import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { useFormContext } from 'react-hook-form';
import {
  Accordion, AccordionDetails, Grid, Box,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import {
  formatter, calculateMaximumDay, intFormatter, POLICY_ITEM_TYPE,
  addlPeriodNames,
} from 'common/Constants';
import { StyledTableCell, StyledTableRow } from '../common/styledTableComponent';
import PolicyTableInputCell from '../common/policyTableInputCell';
import PolicyTableCheckboxCell from '../common/policyTableCheckboxCell';
import PolicyAccordionSummary from '../common/policyAccordionSummary';
import PolicyTableHead from '../common/policyTableHead';
import { updateHICategoryHighlight } from '../state/reducer';

export default function HospitalIndemnity({
  setHospitalIndemnityFormValues,
  isProductIncludedChecked,
}) {
  const {
    getValues, setValue, watch, formState: { errors },
  } = useFormContext();

  const claim = useSelector((state) => state.scenario.claim);

  const claimWeight = useSelector(
    (state) => state.scenario.claimWeight,
  );

  const hospitalIndemnityCategoryCode = useSelector(
    (state) => state.scenario.HICategoryCode,
  );

  const HIModuleList = useSelector((state) => state.scenario.HIModuleList);
  const HIPayoutDetail = useSelector((state) => state.scenario.HIPayoutDetail);
  const dispatch = useDispatch();

  const blankFlagObject = {};
  HIModuleList.forEach((item) => {
    blankFlagObject[item.code] = null;
  });

  const watcher = [];
  HIModuleList.forEach((moduleItem) => {
    watcher.push([]);
    hospitalIndemnityCategoryCode[moduleItem.code].forEach((item) => {
      watcher[watcher.length - 1].push(watch(`${item.name}Checked`));
    });
  });

  const [checkedChangingFlag, setCheckedChangingFlag] = useState(false);
  const [payout, setPayout] = useState({});
  const [totalClaims, setTotalClaims] = useState({});
  const [totalSpent, setTotalSpent] = useState({});
  const [expanded, setExpanded] = useState({ ...blankFlagObject, [HIModuleList[0].code]: true });
  const [headerChecked, setHeaderChecked] = useState(blankFlagObject);
  const [headerIndeterminate, setHeaderIndeterminate] = useState(blankFlagObject);

  const setHeaderCheckboxStatus = () => {
    const output = {};
    watcher.forEach((flag, index) => {
      const n = flag.length;
      const m = flag.reduce((acc, val) => acc + val, 0);
      if (m === 0) {
        setHeaderChecked((prev) => ({ ...prev, [HIModuleList[index].code]: false }));
        setHeaderIndeterminate((prev) => ({ ...prev, [HIModuleList[index].code]: false }));
        output[HIModuleList[index].code] = true;
      } else if (m === n) {
        setHeaderChecked((prev) => ({ ...prev, [HIModuleList[index].code]: true }));
        setHeaderIndeterminate((prev) => ({ ...prev, [HIModuleList[index].code]: false }));
        output[HIModuleList[index].code] = false;
      } else {
        setHeaderChecked((prev) => ({ ...prev, [HIModuleList[index].code]: false }));
        setHeaderIndeterminate((prev) => ({ ...prev, [HIModuleList[index].code]: true }));
        output[HIModuleList[index].code] = true;
      }
    });
    return output;
  };

  useEffect(() => {
    const allItems = Object.values(hospitalIndemnityCategoryCode).flat();
    const itemNames = allItems.flatMap((item) => [item.name, `${item.name}Checked`]);
    const filteredValues = Object.keys(getValues()).reduce((acc, key) => {
      if (itemNames.includes(key)) {
        return { ...acc, [key]: getValues(key) };
      }
      return acc;
    }, {});
    setHospitalIndemnityFormValues(filteredValues);
    const subscription = watch((values) => {
      const filteredValuesLatest = Object.keys(values).reduce((acc, key) => {
        if (itemNames.includes(key)) {
          return { ...acc, [key]: getValues(key) };
        }
        return acc;
      }, {});
      setHospitalIndemnityFormValues(filteredValuesLatest);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    const invalidCategories = new Set();

    Object.keys(errors).forEach((error) => {
      let categoryName;
      if (error === 'participationPerc1') {
        categoryName = HIModuleList[0]?.code;
      } else if (error.includes('Period') || addlPeriodNames.includes(error)) {
        categoryName = HIPayoutDetail.find((benefit) => benefit?.periodName === error)
          ?.module.code;
      } else {
        categoryName = HIPayoutDetail.find((benefit) => benefit?.name === error)
          ?.module.code;
      }
      invalidCategories.add(categoryName);
    });
    dispatch(updateHICategoryHighlight({ invalidCategories: [...invalidCategories] }));
  }, [watch()]);

  const updateAllPayout = () => {
    const tempPayout = {};

    Object.entries(hospitalIndemnityCategoryCode).forEach((list) => {
      list[1].forEach((item) => {
        if (item.code in claim) {
          const benefit1 = getValues(item.name);
          const benefit2 = getValues(`${item.name}2`);
          const count = Math.round(claim[item.code].totalCount * claimWeight.HI);
          const participationPerc1 = getValues('participationPerc1') / 100;
          const participationPerc2 = getValues('participationPerc2') / 100;
          const initialPayout = (benefit1 * (participationPerc1 * count))
                            + (benefit2 * (participationPerc2 * count));

          if (item.type === POLICY_ITEM_TYPE.PERIOD) {
            tempPayout[item.name] = Math.round(initialPayout
                * calculateMaximumDay(getValues(item.periodName), item.periodCode)) || 0;
          } else {
            tempPayout[item.name] = initialPayout || 0;
          }
        }
      });
    });

    Object.entries(hospitalIndemnityCategoryCode).forEach((list) => {
      list[1].forEach((item) => {
        if (item.code in claim) {
          setPayout((prev) => ({
            ...prev,
            [item.name]: tempPayout[item.name],
          }));
        }
      });
    });

    HIModuleList.forEach((moduleItem) => {
      setTotalSpent((prev) => ({
        ...prev,
        [moduleItem.code]: hospitalIndemnityCategoryCode[moduleItem.code].reduce((
          acc,
          item,
        ) => (item.name in tempPayout
          ? acc + tempPayout[item.name] * Number(getValues(`${item.name}Checked`))
          : acc), 0),
      }));
    });
  };

  const onClickHeaderCheckbox = (key) => {
    const flag = setHeaderCheckboxStatus();
    hospitalIndemnityCategoryCode[key].forEach((item) => {
      setValue(`${item.name}Checked`, flag[key]);
    });
    setCheckedChangingFlag(!checkedChangingFlag);
    updateAllPayout();
  };

  const onCheckedFieldChange = () => {
    setCheckedChangingFlag(!checkedChangingFlag);
  };

  useEffect(() => {
    updateAllPayout();

    HIModuleList.forEach((moduleItem) => {
      setTotalClaims((prev) => ({
        ...prev,
        [moduleItem.code]: hospitalIndemnityCategoryCode[moduleItem.code].reduce((acc, item) => {
          if (item.code in claim) {
            if ((item.type === POLICY_ITEM_TYPE.PERIOD || item.name === 'facilityOpSurgery') && item.relatedName !== null && getValues(`${item.relatedName}Checked`) !== undefined) {
              return acc + Math.round(claim[item.code].totalCount * claimWeight.HI) * Math.min(Number(getValues(`${item.name}Checked`))
                + Number(getValues(`${item.relatedName}Checked`)), 1)
                - Math.round(claim[item.code].totalCount * claimWeight.HI) * Number(getValues(`${item.relatedName}Checked`));
            }
            return acc + Math.round(claim[item.code].totalCount * claimWeight.HI) * Number(getValues(`${item.name}Checked`));
          }
          return acc;
        }, 0),
      }));
    });
  }, [claim, claimWeight, checkedChangingFlag]);

  useEffect(() => {
    setHeaderCheckboxStatus();
    updateAllPayout();
  }, []);

  useEffect(() => {
    setHeaderCheckboxStatus();
  }, [checkedChangingFlag]);

  const renderPeriod = (item, tableType) => {
    if (!item || tableType !== 'PERIOD') {
      return null;
    }
    if (item.type === POLICY_ITEM_TYPE.PERIOD) {
      return (
        <PolicyTableInputCell
          name={item.periodName}
          onChange={updateAllPayout}
          max={100}
          isDisabled={!isProductIncludedChecked}
        />
      );
    }
    return (<StyledTableCell />);
  };

  return (
    <Grid container spacing={2}>
      {HIModuleList.map((moduleItem, i) => (

        hospitalIndemnityCategoryCode[moduleItem.code].length > 0
        && (
          <Grid item xs={12}>
            <Accordion expanded={expanded[moduleItem.code]}>
              <PolicyAccordionSummary
                id={`HI-${moduleItem.code}`}
                title={moduleItem.displayName}
                onChange={onClickHeaderCheckbox}
                setExpanded={setExpanded}
                moduleCode={moduleItem.code}
                checked={headerChecked}
                indeterminate={headerIndeterminate}
                totalClaims={totalClaims[moduleItem.code]}
                totalSpent={totalSpent[moduleItem.code]}
                isInvalid={moduleItem.isInvalid}
                isDisabled={!isProductIncludedChecked}
              />
              <AccordionDetails sx={{ padding: 0 }}>
                <TableContainer component={Paper} margin={0}>
                  <Table aria-label="main table">
                    <PolicyTableHead type={moduleItem.tableType} />
                    <TableBody>
                      <Box />
                      <StyledTableCell align="left" component="th" scope="row">
                        Participation %
                      </StyledTableCell>
                      <PolicyTableInputCell
                        name="participationPerc1"
                        onChange={updateAllPayout}
                        adornment="percent"
                        isDisabled={i !== 0 || !isProductIncludedChecked}
                      />
                      <PolicyTableInputCell
                        name="participationPerc2"
                        onChange={() => {}}
                        adornment="percent"
                        isDisabled
                      />
                      {hospitalIndemnityCategoryCode[moduleItem.code].map((item) => (
                        <StyledTableRow>
                          <PolicyTableCheckboxCell
                            name={`${item.name}Checked`}
                            onChange={onCheckedFieldChange}
                            isDisabled={!isProductIncludedChecked}
                          />
                          <StyledTableCell align="left" component="th" scope="row">
                            {item.displayName}
                          </StyledTableCell>
                          <PolicyTableInputCell
                            name={item.name}
                            onChange={updateAllPayout}
                            adornment={item.name !== 'admitsLimitPerYear' && 'dollar'}
                            isDisabled={!isProductIncludedChecked}
                          />
                          <PolicyTableInputCell
                            name={`${item.name}2`}
                            onChange={updateAllPayout}
                            adornment={item.name !== 'admitsLimitPerYear' && 'dollar'}
                            isBenefit2
                            isDisabled={!isProductIncludedChecked}
                          />
                          {renderPeriod(item, moduleItem.tableType)}
                          <StyledTableCell align="left">
                            {item.code in claim
                              ? intFormatter.format(
                                Math.round(claim[item.code].totalCount * claimWeight.HI),
                              )
                              : null}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {item.name in payout ? formatter.format(payout[item.name]) : null}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          </Grid>
        )

      ))}
    </Grid>

  );
}
