import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Button, Dialog, Divider, DialogActions, DialogContent, DialogTitle, Checkbox,
  Tooltip, Box, Typography, Switch,
} from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useForm, FormProvider } from 'react-hook-form';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Warning from '@mui/icons-material/Warning';
import HospitalIndemnity from './hospitalIndemnity';

export default function HospitalIndemnityDialog(props) {
  const {
    open, handleClose, title, save, formValues,
  } = props;
  const [isDefaultChecked, setDefaultChecked] = useState(false);
  const scenario = useSelector((state) => state.scenario.scenario);
  const hospitalIndemnityObject = useSelector((state) => state.scenario.hospitalIndemnityObject);
  const [isProductIncludedChecked, setProductIncludedChecked] = useState(true);
  const isNewScenario = useSelector((state) => state.navigator.isNewScenario);
  const participationPercs = useSelector((state) => state.scenario.participationPercentages.HI);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const methods = useForm({ defaultValues: { ...hospitalIndemnityObject, ...participationPercs }, mode: 'onBlur' });
  const { reset, handleSubmit } = methods;
  const [hospitalIndemnityFormValues, setHospitalIndemnityFormValues] = useState(formValues);
  const [initialProductIncludedChecked, setInitialProductIncludedChecked] = useState(true);

  useEffect(() => {
    reset({ ...hospitalIndemnityObject, ...participationPercs });
    if (open) {
      setDefaultChecked(hospitalIndemnityObject?.defaultSaveChecked);
      setProductIncludedChecked(isProductIncludedChecked);
      setInitialProductIncludedChecked(isProductIncludedChecked);
    } if (hospitalIndemnityObject.scenarioId === scenario.id) {
      setProductIncludedChecked(hospitalIndemnityObject?.productIncludedChecked);
    }
  }, [hospitalIndemnityObject, open, participationPercs]);

  const getCheckedItems = () => {
    const checkedItems = [];
    if (hospitalIndemnityFormValues) {
      Object.keys(hospitalIndemnityFormValues).forEach((key) => {
        if (key.toLowerCase().includes('checked') && hospitalIndemnityFormValues[key]) {
          const itemName = key.replace(/checked/i, '');
          if (Number(hospitalIndemnityFormValues[itemName]) !== 0) {
            checkedItems.push({ name: itemName, value: hospitalIndemnityFormValues[itemName] });
            checkedItems.push({ name: key, value: hospitalIndemnityFormValues[key] });
          }
        }
      });
    }
    return checkedItems;
  };

  const handleSaveClick = () => {
    const item = getCheckedItems();
    if (item.length || openCancelDialog) {
      if (isNewScenario) {
        handleSubmit((data) => save({
          ...data,
          defaultValueChecked: isDefaultChecked,
          defaultSaveChecked: isDefaultChecked,
          productIncludedChecked: isProductIncludedChecked,
        }))();
      } else {
        handleSubmit((data) => save({
          ...data,
          productIncludedChecked: isProductIncludedChecked,
        }))();
      }
    } else {
      setOpenCancelDialog(true);
    }
  };

  const handleCancelClick = () => {
    setProductIncludedChecked(initialProductIncludedChecked);
    handleClose();
  };

  return (
    <FormProvider {...methods}>
      <Dialog open={open} onClose={handleCancelClick} fullWidth maxWidth="xl">
        <DialogTitle>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">{title}</Typography>
            <Box display="flex" alignItems="center">
              <Typography variant="h6">Include Product</Typography>
              <Switch
                size="medium"
                checked={isProductIncludedChecked}
                onChange={() => setProductIncludedChecked(!isProductIncludedChecked)}
              />
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <HospitalIndemnity
            setHospitalIndemnityFormValues={setHospitalIndemnityFormValues}
            isProductIncludedChecked={isProductIncludedChecked}
          />
        </DialogContent>
        <DialogActions>
          {isNewScenario && (
          <Box style={{ flex: '1', paddingLeft: '43px' }}>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={isDefaultChecked}
                  onChange={() => setDefaultChecked(!isDefaultChecked)}
                  disabled={!isProductIncludedChecked}
                />
)}
              label="Save changes as my defaults"
            />
            <Tooltip
              style={{ margin: '0 0 -4px -11px' }}
              title="The user-saved defaults should apply the next time the user creates a scenario."
            >
              <InfoOutlinedIcon fontSize="small" />
            </Tooltip>
          </Box>
          )}
          <Button onClick={handleSaveClick}>
            Save
          </Button>
          <Button onClick={handleCancelClick}>Cancel</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openCancelDialog} onClose={() => setOpenCancelDialog(false)}>
        <DialogTitle sx={{ fontSize: '1.25rem', color: '#002E74' }}>
          <Warning sx={{ marginBottom: '-5px' }} />
          &nbsp;
          No Projected Claims for product?
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ fontSize: '1rem', marginBottom: '1rem' }}>
          <Typography>
            To include this product on the output page,
            please select at least one benefit with projected claims.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenCancelDialog(false)}
            color="primary"
          >
            SELECT BENEFIT(S)
          </Button>
          <Button
            onClick={() => { handleSaveClick(); setOpenCancelDialog(false); }}
            color="primary"
            variant="contained"
          >
            SAVE WITHOUT BENEFIT(S)
          </Button>
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
}
