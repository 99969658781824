import React, { useState, useEffect, useRef } from 'react';
import {
  Card, Grid, Typography, Divider, Box, Table, TableHead, TableBody,
  TableContainer, TableRow, Paper, Tooltip,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useSelector } from 'react-redux';
import {
  BarChart, Bar, XAxis, YAxis,
  CartesianGrid, ResponsiveContainer, Label,
} from 'recharts';
import ExportImage from 'common/components/exportImage';
import BottomBar from 'features/navigation/bottomBar';
import {
  steps, PRIMARY_DARK, formatter, intFormatter, POLICY_CODE,
  POLICY_ITEM_TYPE, calculateMaximumDay, NUMBERS_ROUNDED_TEXT,
  percFormatterNoDecimal,
} from 'common/Constants';
import { grey } from '@mui/material/colors';
import { StyledTableCell, StyledTableRow } from './common/styledTableComponent';

const gridItemStyle = {
  display: 'flex',
  alignItems: 'center',
  height: '450px',
};
export default function Coverage(props) {
  const { bottomBarFunctions } = props;
  const { handleCancelCreation, goToNextPage, goToPreviousPage } = bottomBarFunctions;

  const subpage = useSelector((state) => state.scenario.coveragePage);
  const currPage = useSelector((state) => state.scenario.scenarioSection);
  const benefitsIncludedRef = useRef();
  const benefitsNotIncludedRef = useRef();
  const quickInsightsRef = useRef();
  const pPercent = useSelector((state) => state.scenario.participationPercentages);

  const totalPayout = useSelector(
    (state) => state.scenario.totalPayout,
  );

  const totalCount = useSelector(
    (state) => state.scenario.totalCount,
  );

  const HIPayoutDetail = useSelector(
    (state) => state.scenario.HIPayoutDetail,
  );
  const hospitalIndemnityObject = useSelector(
    (state) => state.scenario.hospitalIndemnityObject,
  );

  const CIPayoutDetail = useSelector(
    (state) => state.scenario.CIPayoutDetail,
  );
  const criticalIllnessObject = useSelector(
    (state) => state.scenario.criticalIllnessObject,
  );

  const ACPayoutDetail = useSelector(
    (state) => state.scenario.ACPayoutDetail,
  );
  const accidentObject = useSelector(
    (state) => state.scenario.accidentObject,
  );

  const [benefitList, setBenefitList] = useState([]);
  const [benefitListCI, setBenefitListCI] = useState([]);

  const getCheckedStatus = (item) => {
    if (item.policyCode === POLICY_CODE.HI) {
      return hospitalIndemnityObject[`${item.name}Checked`];
    } if (item.policyCode === POLICY_CODE.CI) {
      return criticalIllnessObject[`${item.name}Checked`];
    } if (item.policyCode === POLICY_CODE.AC) {
      return accidentObject[`${item.name}Checked`];
    }
    return null;
  };

  const getBenefitValue = (itemName, policyCode) => {
    if (policyCode === POLICY_CODE.HI) {
      return hospitalIndemnityObject[itemName];
    } if (policyCode === POLICY_CODE.AC) {
      return accidentObject[itemName];
    } if (policyCode === POLICY_CODE.CI) {
      return criticalIllnessObject[itemName];
    }
    return null;
  };

  useEffect(() => {
    const tempList = [];
    [...HIPayoutDetail, ...CIPayoutDetail, ...ACPayoutDetail].forEach((item) => {
      if ('payout' in item) {
        tempList.push(item);
      }
    });
    setBenefitList(tempList);

    const allCIBenefits = [];
    for (let i = 1; i <= 5; i += 1) {
      const name = (i === 1) ? 'faceValue' : `faceValueOption${i}`;
      const enrollmentName = (i === 1) ? 'expectedEnrollment' : `expectedEnrollmentOption${i}`;
      const enrollment = criticalIllnessObject[`${enrollmentName}`] / 100;
      const faceValueItem = {
        policyCode: POLICY_CODE.CI,
        displayName: `Face Value - Option ${i}`,
        name,
        amount: formatter.format(criticalIllnessObject[`${name}`]),
        enrollment,
        payout: { TOTAL: Math.round(totalPayout.CI.payout.EMPLOYEE * enrollment) },
        count: { TOTAL: Math.round(totalCount.CI.count.EMPLOYEE * enrollment) },
      };
      allCIBenefits.push(faceValueItem);
    }

    [...CIPayoutDetail].forEach((item) => {
      if ('payout' in item) {
        allCIBenefits.push(item);
      } else {
        const newItem = {
          ...item,
          amount: percFormatterNoDecimal.format(criticalIllnessObject[`${item?.name}`] / 100),
          payout: { TOTAL: (item.name === 'adultDp') ? totalPayout.CI.payout.SPOUSE : totalPayout.CI.payout.CHILDREN },
          count: { TOTAL: (item.name === 'adultDp') ? totalCount.CI.count.SPOUSE : totalCount.CI.count.CHILDREN },
        };
        allCIBenefits.push(newItem);
      }
    });
    setBenefitListCI(allCIBenefits);
  }, [currPage]);

  const dataHI = benefitList
    .filter((item) => item.policyCode === POLICY_CODE.HI && getCheckedStatus(item))
    .sort((a, b) => b.count.TOTAL - a.count.TOTAL)
    .map((item) => ({ name: item.displayName, value: item.count.TOTAL }));

  const dataCI = benefitList
    .filter((item) => item.policyCode === POLICY_CODE.CI && getCheckedStatus(item))
    .sort((a, b) => b.count.TOTAL - a.count.TOTAL)
    .map((item) => ({ name: item.displayName, value: item.count.TOTAL }));

  const dataAC = benefitList
    .filter((item) => item.policyCode === POLICY_CODE.AC && getCheckedStatus(item))
    .sort((a, b) => b.count.TOTAL - a.count.TOTAL)
    .map((item) => ({ name: item.displayName, value: item.count.TOTAL }));

  function reverseSameValueItems(data) {
    if (!data || data.length === 0) {
      return [];
    }
    let groupedData = [];
    let tempGroup = [data[0]];

    for (let i = 1; i < data.length; i += 1) {
      if (data[i].value === data[i - 1].value) {
        tempGroup.push(data[i]);
      } else {
        if (tempGroup.length > 1) {
          tempGroup.reverse();
        }
        groupedData = groupedData.concat(tempGroup);
        tempGroup = [data[i]];
      }
    }
    if (tempGroup.length > 1) {
      tempGroup.reverse();
    }
    groupedData = groupedData.concat(tempGroup);

    return groupedData;
  }

  let data;
  if (subpage === 0) {
    data = reverseSameValueItems(dataHI);
  } else if (subpage === 1) {
    data = reverseSameValueItems(dataCI);
  } else {
    data = reverseSameValueItems(dataAC);
  }

  const highLowBenefitList = benefitList
    .filter((item) => item.policyCode === POLICY_CODE.HI || item.policyCode === POLICY_CODE.AC)
    .map((item) => {
      let payout1;
      const participationRate1 = ((item.policyCode === POLICY_CODE.HI)
        ? pPercent.HI.participationPerc1 : pPercent.AC.participationPerc1) / 100;
      const participationRate2 = ((item.policyCode === POLICY_CODE.HI)
        ? pPercent.HI.participationPerc2 : pPercent.AC.participationPerc2) / 100;
      const benefit1 = getBenefitValue(item.name, item.policyCode);
      const noOfClaims = item.count.TOTAL;

      if (item.type === POLICY_ITEM_TYPE.ORIGINAL) {
        payout1 = benefit1 * (participationRate1 * noOfClaims);
      } else if (item.type === POLICY_ITEM_TYPE.PERIOD) {
        const maxDayCalc = calculateMaximumDay(item.maximumDay, item.periodCode);
        payout1 = (benefit1 * (participationRate1 * noOfClaims)) * maxDayCalc;
      } else {
        const closed1 = getBenefitValue(`${item.name}Closed`, item.policyCode);
        const open1 = getBenefitValue(`${item.name}Open`, item.policyCode);
        const fractureWeight = item.fractureInjuriesWeight;
        payout1 = Math.round((closed1 * fractureWeight + open1
          * (1 - fractureWeight)) * (participationRate1 * noOfClaims));
      }
      const count1 = Math.round(participationRate1 * noOfClaims) || 0;
      const count2 = Math.round(participationRate2 * noOfClaims) || 0;
      const payout2 = Math.abs(item.payout.TOTAL - payout1);

      return {
        ...item,
        count: {
          ...item.count,
          BENEFIT1: count1,
          BENEFIT2: count2,
        },
        payout: {
          ...item.payout,
          BENEFIT1: payout1,
          BENEFIT2: payout2,
        },
      };
    });

  const benefitListEmpty = benefitList.filter((item) => (
    (item.policyCode === POLICY_CODE.HI && subpage === 0 && getCheckedStatus(item))
    || (item.policyCode === POLICY_CODE.AC && subpage === 2 && getCheckedStatus(item))
    || (item.policyCode === POLICY_CODE.CI && subpage === 1 && getCheckedStatus(item))
  )).length === 0;

  const benefitListNotEmpty = benefitList.filter((item) => (
    ((item.policyCode === POLICY_CODE.HI && subpage === 0 && !hospitalIndemnityObject[`${item?.name}Checked`])
      || (item.policyCode === POLICY_CODE.CI && subpage === 1 && !criticalIllnessObject[`${item?.name}Checked`])
      || (item.policyCode === POLICY_CODE.AC && subpage === 2 && !accidentObject[`${item?.name}Checked`]
      )))).length === 0;

  const hideBenefit1 = (subpage === 0 && pPercent.HI.participationPerc1 === 0)
    || (subpage === 2 && pPercent.AC.participationPerc1 === 0);
  const hideBenefit2 = (subpage === 0 && pPercent.HI.participationPerc2 === 0)
    || (subpage === 2 && pPercent.AC.participationPerc2 === 0);

  return (
    <>
      <Grid container spacing={2} paddingBottom={3}>
        <Grid item xs={12}>
          <Typography variant="h4">
            Coverage for
            {' '}
            {steps()[3].sublabel[subpage]}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            Below are the most relevant benefits for each product.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Card ref={quickInsightsRef}>
            <Grid container spacing={2}>
              <Grid item xs={12} variant="addPaddingBottom" container justifyContent="space-between">
                <Typography variant="h5">
                  {`Quick Insights - ${steps()[3].sublabel[subpage]}`}
                </Typography>
                <ExportImage sectionRef={quickInsightsRef} sectionName="quick_insights" />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={3}>
                <Grid container spacing={1} direction="column">
                  <Grid item xs={12}>
                    <Box
                      maxWidth
                      sx={{
                        border: 1,
                        borderRadius: '9px',
                        borderColor: grey[400],
                        width: '17rem',
                        padding: '1.5rem',
                        marginBottom: '1.25rem',
                      }}
                    >
                      <Typography
                        variant="h3"
                        align="center"
                        color={PRIMARY_DARK}
                        sx={{
                          zIndex: 1450, margin: '24px', fontWeight: '600', fontSize: '1.5rem',
                        }}
                      >
                        {subpage === 0 && intFormatter.format(totalCount.HI.count.TOTAL)}
                        {subpage === 1 && intFormatter.format(totalCount.CI.count.TOTAL)}
                        {subpage === 2 && intFormatter.format(totalCount.AC.count.TOTAL)}
                      </Typography>
                      <Typography align="center" color={grey[700]} marginBottom={2} sx={{ marginTop: '0.5rem' }}>
                        Projected Number of Claims
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      maxWidth
                      sx={{
                        border: 1,
                        borderRadius: '9px',
                        borderColor: grey[400],
                        width: '17rem',
                        padding: '1.5rem',
                        marginBottom: '1.25rem',
                      }}
                    >
                      <Typography
                        variant="h3"
                        align="center"
                        color={PRIMARY_DARK}
                        sx={{
                          zIndex: 1450, margin: '24px', fontWeight: '600', fontSize: '1.5rem',
                        }}
                      >
                        {subpage === 0 && (totalPayout.HI.payout.TOTAL === 0 ? 0
                          : formatter.format(
                            (totalPayout.HI.payout.TOTAL / totalCount.HI.count.TOTAL) || 0,
                          ))}
                        {subpage === 1 && (totalPayout.CI.payout === 0 ? 0
                          : formatter.format(
                            (totalPayout.CI.payout.TOTAL / totalCount.CI.count.TOTAL) || 0,
                          ))}
                        {subpage === 2 && (totalPayout.AC.payout.TOTAL === 0 ? 0
                          : formatter.format(
                            (totalPayout.AC.payout.TOTAL / totalCount.AC.count.TOTAL) || 0,
                          ))}
                      </Typography>
                      <Typography align="center" color={grey[700]} marginBottom={2} sx={{ marginTop: '0.5rem' }}>
                        Average Claim
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      maxWidth
                      sx={{
                        border: 1,
                        borderRadius: '9px',
                        borderColor: grey[400],
                        width: '17rem',
                        padding: '1.5rem',
                        marginBottom: '1.25rem',
                      }}
                    >
                      <Typography
                        variant="h3"
                        align="center"
                        color={PRIMARY_DARK}
                        sx={{
                          zIndex: 1450, margin: '24px', fontWeight: '600', fontSize: '1.5rem',
                        }}
                      >
                        {subpage === 0 && formatter.format(totalPayout.HI.payout.TOTAL)}
                        {subpage === 1 && formatter.format(totalPayout.CI.payout.TOTAL)}
                        {subpage === 2 && formatter.format(totalPayout.AC.payout.TOTAL)}
                      </Typography>
                      <Typography align="center" color={grey[700]} marginBottom={2} sx={{ marginTop: '0.5rem' }}>
                        Projected Claims
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={9}>
                {data.length > 0 ? (
                  <ResponsiveContainer width={800} height={570}>
                    <BarChart
                      data={data.slice(0, 10)}
                      layout="vertical"
                      margin={{ left: 20, right: 20 }}
                    >
                      <CartesianGrid horizontal={false} vertical color="#212121" />
                      <XAxis type="number" dataKey="value" orientation="bottom" stroke="#212121" tickLine={false} axisLine={false} height={55}>
                        <Label value="Claims per Category" position="insideBottom" style={{ fill: '#212121' }} />
                      </XAxis>
                      <YAxis
                        type="category"
                        dataKey="name"
                        width={300}
                        axisLine={false}
                        tickLine={false}
                        textAnchor="end"
                        dx={-10}
                        style={{ fill: '#212121', overflow: 'hidden', textOverflow: 'ellipsis' }}
                      />
                      <Bar dataKey="value" fill="#002E74" />
                    </BarChart>
                  </ResponsiveContainer>
                ) : (
                  <Grid item xs={12} sx={gridItemStyle}>
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography align="center" marginTop={10} marginBottom={2}>
                        No data to display
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>

      <Grid item xs={12} paddingBottom={3}>
        <Card ref={benefitsIncludedRef}>
          <Grid container spacing={2}>
            <Grid item xs={12} variant="addPaddingBottom" container justifyContent="space-between">
              <Typography variant="h5">
                {`Benefits Included - ${steps()[3].sublabel[subpage]}`}
              </Typography>
              <ExportImage sectionRef={benefitsIncludedRef} sectionName="benefits_included" />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {/* Hospital Indemnity & Accident Subpages */}
            {subpage === 0 || subpage === 2 ? (
              <Grid container spacing={4}>
                {benefitListEmpty ? (
                  <Grid item xs={12}>
                    <Typography align="center" marginTop={6} marginBottom={2}>
                      No data to display
                    </Typography>
                  </Grid>
                ) : (
                  <>
                    {!hideBenefit1
                      && (
                      <Grid item xs={hideBenefit2 ? 12 : 6}>
                        <Typography variant="h6" align="center" marginTop={3} marginBottom={2} color="#002E74" marginLeft="2rem">Benefit 1</Typography>
                        <TableContainer component={Paper} margin={0} elevation={2} sx={hideBenefit2 ? { marginLeft: '0.5rem', marginRight: '0.5rem' } : { marginLeft: '1rem' }}>
                          <Table aria-label="main table">
                            <TableHead>
                              <TableRow>
                                <StyledTableCell align="left" style={{ verticalAlign: 'bottom' }}>
                                  {' '}
                                  <Typography fontWeight="600">Benefit</Typography>
                                  {' '}
                                </StyledTableCell>
                                <StyledTableCell align="right" style={{ verticalAlign: 'bottom' }}>
                                  {' '}
                                  <Typography fontWeight="600">Average Projected Claim</Typography>
                                  {' '}
                                </StyledTableCell>
                                <StyledTableCell align="right" style={{ verticalAlign: 'bottom' }}>
                                  <Box sx={{ display: 'flex', justifyContent: 'end', gap: '0.5rem' }}>
                                    <Box>
                                      <Typography fontWeight="600">
                                        Claims
                                      </Typography>
                                    </Box>
                                    <Box>
                                      <Tooltip
                                        style={{ paddingTop: '3px' }}
                                        title={NUMBERS_ROUNDED_TEXT}
                                      >
                                        <InfoOutlinedIcon fontSize="small" />
                                      </Tooltip>
                                    </Box>
                                  </Box>
                                </StyledTableCell>
                                <StyledTableCell align="right" style={{ verticalAlign: 'bottom' }}>
                                  {' '}
                                  <Typography fontWeight="600">Projected Claims</Typography>
                                  {' '}
                                </StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {highLowBenefitList.map((item) => (
                                ((item.policyCode === POLICY_CODE.HI && subpage === 0
                                    && hospitalIndemnityObject[`${item?.name}Checked`])
                                    || (item.policyCode === POLICY_CODE.AC && subpage === 2 && accidentObject[`${item?.name}Checked`])
                                ) && 'payout' in item
                                  ? (
                                    <StyledTableRow>
                                      <StyledTableCell
                                        align="left"
                                        component="th"
                                        scope="row"
                                        style={{ width: '30%' }}
                                      >
                                        {item.displayName}
                                      </StyledTableCell>
                                      <StyledTableCell align="right" style={{ width: '17%' }}>
                                        {formatter.format(
                                          item.payout.BENEFIT1 === 0 || item.count.TOTAL === 0 ? 0
                                            : item.payout.BENEFIT1 / item.count.TOTAL,
                                        )}
                                      </StyledTableCell>
                                      <StyledTableCell align="right" style={{ width: '14%' }}>
                                        {intFormatter.format(item.count.BENEFIT1)}
                                      </StyledTableCell>
                                      <StyledTableCell align="right" style={{ width: '14%' }}>
                                        {formatter.format(item.payout.BENEFIT1)}
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  )
                                  : null
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                      )}
                    {!hideBenefit2
                      && (
                      <Grid item xs={hideBenefit1 ? 12 : 6}>
                        <Typography variant="h6" align="center" marginTop={3} marginBottom={2} color="#002E74">Benefit 2</Typography>
                        <TableContainer component={Paper} margin={0} elevation={2} sx={hideBenefit1 && { marginLeft: '0.5rem', marginRight: '0.5rem' }}>
                          <Table aria-label="main table">
                            <TableHead>
                              <TableRow>
                                <StyledTableCell align="left" style={{ verticalAlign: 'bottom' }}>
                                  {' '}
                                  <Typography fontWeight="600">Benefit</Typography>
                                  {' '}
                                </StyledTableCell>
                                <StyledTableCell align="right" style={{ verticalAlign: 'bottom' }}>
                                  {' '}
                                  <Typography fontWeight="600">Average Projected Claim</Typography>
                                  {' '}
                                </StyledTableCell>
                                <StyledTableCell align="right" style={{ verticalAlign: 'bottom' }}>
                                  <Box sx={{ display: 'flex', justifyContent: 'end', gap: '0.5rem' }}>
                                    <Box>
                                      <Typography fontWeight="600">
                                        Claims
                                      </Typography>
                                    </Box>
                                    <Box>
                                      <Tooltip
                                        style={{ paddingTop: '3px' }}
                                        title={NUMBERS_ROUNDED_TEXT}
                                      >
                                        <InfoOutlinedIcon fontSize="small" />
                                      </Tooltip>
                                    </Box>
                                  </Box>
                                </StyledTableCell>
                                <StyledTableCell align="right" style={{ verticalAlign: 'bottom' }}>
                                  {' '}
                                  <Typography fontWeight="600">Projected Claims</Typography>
                                  {' '}
                                </StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {highLowBenefitList.map((item) => (
                                ((item.policyCode === POLICY_CODE.HI && subpage === 0
                                    && hospitalIndemnityObject[`${item?.name}Checked`])
                                    || (item.policyCode === POLICY_CODE.AC && subpage === 2 && accidentObject[`${item?.name}Checked`])
                                ) && 'payout' in item
                                  ? (
                                    <StyledTableRow>
                                      <StyledTableCell
                                        align="left"
                                        component="th"
                                        scope="row"
                                        style={{ width: '30%' }}
                                      >
                                        {item.displayName}
                                      </StyledTableCell>
                                      <StyledTableCell align="right" style={{ width: '17%' }}>
                                        {formatter.format(
                                          item.payout.BENEFIT2 === 0 || item.count.TOTAL === 0 ? 0
                                            : item.payout.BENEFIT2 / item.count.TOTAL,
                                        )}
                                      </StyledTableCell>
                                      <StyledTableCell align="right" style={{ width: '14%' }}>
                                        {intFormatter.format(item.count.BENEFIT2)}
                                      </StyledTableCell>
                                      <StyledTableCell align="right" style={{ width: '14%' }}>
                                        {formatter.format(item.payout.BENEFIT2)}
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  )
                                  : null
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                      )}
                  </>
                )}
              </Grid>
            ) : (
              // Critical Illness Subpage
              <Grid item xs={12}>
                {benefitListEmpty ? (
                  <Typography align="center" marginTop={6} marginBottom={2}>
                    No data to display
                  </Typography>
                ) : (
                  <TableContainer component={Paper} margin={0}>
                    <Table aria-label="main table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="left">
                            {' '}
                            <Typography fontWeight="600">Benefit</Typography>
                            {' '}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {' '}
                            <Typography fontWeight="600">Amount</Typography>
                            {' '}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {' '}
                            <Typography fontWeight="600">Expected Enrollment %</Typography>
                            {' '}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {' '}
                            <Typography fontWeight="600">Average Projected Claim</Typography>
                            {' '}
                          </StyledTableCell>
                          <StyledTableCell align="right" style={{ verticalAlign: 'bottom' }}>
                            <Grid container spacing={2} alignItems="center">
                              <Grid item xs={5}>
                                <Typography fontWeight="600">
                                  Claims
                                </Typography>
                              </Grid>
                              <Grid item xs={2}>
                                <Tooltip
                                  style={{ paddingTop: '3px' }}
                                  title={NUMBERS_ROUNDED_TEXT}
                                >
                                  <InfoOutlinedIcon fontSize="small" sx={{ marginTop: '4px' }} />
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {' '}
                            <Typography fontWeight="600">Projected Claims</Typography>
                            {' '}
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {benefitListCI.map((item) => (
                          (item.policyCode === POLICY_CODE.CI && subpage === 1 && criticalIllnessObject[`${item?.name}Checked`])
                            ? (
                              <StyledTableRow>
                                <StyledTableCell
                                  align="left"
                                  component="th"
                                  scope="row"
                                  style={{ width: '30%' }}
                                >
                                  {item.displayName}
                                </StyledTableCell>
                                <StyledTableCell align="center" style={{ width: '17%' }}>
                                  {('amount' in item) ? item.amount : null}
                                </StyledTableCell>
                                <StyledTableCell align="center" style={{ width: '17%' }}>
                                  {('enrollment' in item)
                                    ? percFormatterNoDecimal.format(item.enrollment) : null}
                                </StyledTableCell>
                                <StyledTableCell align="center" style={{ width: '17%' }}>
                                  {formatter.format(
                                    item.payout.TOTAL === 0 || item.count.TOTAL === 0 ? 0
                                      : item.payout.TOTAL / item.count.TOTAL,
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center" style={{ width: '14%' }}>
                                  {intFormatter.format(item.count.TOTAL)}
                                </StyledTableCell>
                                <StyledTableCell align="right" style={{ width: '14%' }}>
                                  {formatter.format(item.payout.TOTAL)}
                                </StyledTableCell>
                              </StyledTableRow>
                            )
                            : null
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Grid>
            )}
          </Grid>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card ref={benefitsNotIncludedRef}>
          <Grid container spacing={2}>
            <Grid item xs={12} variant="addPaddingBottom" container justifyContent="space-between">
              <Typography variant="h5">
                {`Benefits Not Included - ${steps()[3].sublabel[subpage]}`}
              </Typography>
              <ExportImage sectionRef={benefitsNotIncludedRef} sectionName="benefits_not_included" />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid container spacing={4}>
              {benefitListNotEmpty ? (
                <Grid item xs={12}>
                  <Typography align="center" marginTop={6} marginBottom={2}>
                    No data to display
                  </Typography>
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <TableContainer component={Paper} margin={0}>
                    <Table aria-label="main table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="left">
                            {' '}
                            <Typography fontWeight="600">Benefit</Typography>
                            {' '}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {' '}
                            <Typography fontWeight="600">Average Projected Claim</Typography>
                            {' '}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {' '}
                            <Typography fontWeight="600">Claims</Typography>
                            {' '}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {' '}
                            <Typography fontWeight="600">Projected Claims</Typography>
                            {' '}
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {benefitList.map((item) => (
                          ((item.policyCode === POLICY_CODE.HI && subpage === 0
                        && !hospitalIndemnityObject[`${item?.name}Checked`])
                          || (item.policyCode === POLICY_CODE.CI && subpage === 1 && !criticalIllnessObject[`${item?.name}Checked`])
                          || (item.policyCode === POLICY_CODE.AC && subpage === 2 && !accidentObject[`${item?.name}Checked`]
                          )) && 'payout' in item
                            ? (
                              <StyledTableRow>
                                <StyledTableCell
                                  align="left"
                                  component="th"
                                  scope="row"
                                  style={{ width: '30%' }}
                                >
                                  {item.displayName}
                                </StyledTableCell>
                                <StyledTableCell align="center" style={{ width: '17%' }}>
                                  {formatter.format(
                                    item.payout.TOTAL === 0 || item.count.TOTAL === 0 ? 0
                                      : item.payout.TOTAL / item.count.TOTAL,
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="center" style={{ width: '14%' }}>
                                  {intFormatter.format(item.count.TOTAL)}
                                </StyledTableCell>
                                <StyledTableCell align="right" style={{ width: '14%' }}>
                                  {formatter.format(item.payout.TOTAL)}
                                </StyledTableCell>
                              </StyledTableRow>
                            )
                            : null
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <BottomBar
          firstButtonDisplayName="EXIT"
          secondButtonDisplayName="GO TO OUTPUT"
          onClickFirstButton={handleCancelCreation}
          onClickSecondButton={goToNextPage}
          isBackButton
          onClickBackButton={goToPreviousPage}
        />
      </Grid>
    </>
  );
}
