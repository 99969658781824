import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Typography } from '@mui/material';
import * as Constants from 'common/Constants';
import { useSelector } from 'react-redux';
import store from 'app/store';
import CancelScenarioDialog from './cancelScenarioDialog';
import { updateScenarioSection, updateCoveragePage, updateFormState } from '../state/reducer';
import CensusFileReminderDialog from './censusFileReminder';

export default function VerticalStepper() {
  const accidentObject = useSelector((state) => state.scenario.accidentObject);
  const criticalIllnessObject = useSelector((state) => state.scenario.criticalIllnessObject);
  const hospitalIndemnityObject = useSelector((state) => state.scenario.hospitalIndemnityObject);
  const currPage = useSelector((state) => state.scenario.scenarioSection);
  const currCoveragePage = useSelector((state) => state.scenario.coveragePage);
  const scenarioId = useSelector((state) => state.scenario.scenarioId);
  const processStatus = useSelector((state) => state.scenario.processStatus);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [cancelDialogOpen, setCancelDialogOpen] = React.useState(false);
  const formStateValue = useSelector((state) => state.scenario.isModified);
  const [dialogIndex, setDialogIndex] = React.useState(null);
  const [dialogSubindex, setDialogSubindex] = React.useState(null);
  const { productIncludedChecked: HIProductIncluded } = hospitalIndemnityObject;
  const { productIncludedChecked: CIProductIncluded } = criticalIllnessObject;
  const { productIncludedChecked: ACProductIncluded } = accidentObject;
  const singleProductSelected = HIProductIncluded || CIProductIncluded || ACProductIncluded;

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const redirect = (toPage) => {
    if (toPage !== currPage) {
      store.dispatch(updateScenarioSection(toPage));
    }
  };

  const cancelScenarioClose = () => {
    setCancelDialogOpen(false);
  };

  const getEnabled = (index, subIndex) => {
    if ((subIndex === 0 && !HIProductIncluded) || (subIndex === 1 && !CIProductIncluded)
      || (subIndex === 2 && !ACProductIncluded)) {
      return 'grey';
    }
    if (currPage === index && currCoveragePage === subIndex) {
      return Constants.PRIMARY_DARK;
    }

    return 'black';
  };

  const handleCoverageStepClick = (index, subindex, screen) => {
    if ((subindex === 0 && HIProductIncluded) || (subindex === 1 && CIProductIncluded)
    || (subindex === 2 && ACProductIncluded)) {
      if (processStatus === Constants.STATUS_CODE.PYTHON_INVALID) {
        handleDialogOpen();
      }
      if ((screen || !formStateValue) && scenarioId && scenarioId > 0
        && processStatus === Constants.STATUS_CODE.SUCCESS) {
        store.dispatch(updateCoveragePage(subindex));
        redirect(index);
        store.dispatch(updateFormState(false));
      }
      if (formStateValue && scenarioId && scenarioId > 0) {
        setCancelDialogOpen(formStateValue);
        setDialogIndex(index);
        setDialogSubindex(subindex);
      }
    }
  };

  const redirectCoverageStep = (index, screen) => {
    if (index === 3 && HIProductIncluded) {
      handleCoverageStepClick(index, 0, screen);
      return;
    }
    if (index === 3 && CIProductIncluded) {
      handleCoverageStepClick(index, 1, screen);
      return;
    }
    if (index === 3 && ACProductIncluded) {
      handleCoverageStepClick(index, 2, screen);
    }
  };

  const handleStepClick = (index, screen) => {
    if (index === 3 && !singleProductSelected) {
      return;
    }
    redirectCoverageStep(index, screen);
    if (processStatus === Constants.STATUS_CODE.PYTHON_INVALID && index >= 1) {
      handleDialogOpen();
      return;
    }
    if ((screen || !formStateValue) && scenarioId > 0
    && processStatus === Constants.STATUS_CODE.SUCCESS) {
      redirect(index);
      store.dispatch(updateFormState(false));
    }
    if (formStateValue && scenarioId && scenarioId > 0) {
      setCancelDialogOpen(formStateValue);
      setDialogIndex(index);
    }
  };

  const carrierSteps = React.useMemo(() => Constants.steps(), []);

  return (
    <Box>
      <Stepper
        nonLinear
        orientation="vertical"
        connector={null}
        activeStep={currPage}
      >
        {carrierSteps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel>
              <Typography
                fontWeight={currPage === index ? 'bold' : 'normal'}
                color={currPage === index ? Constants.PRIMARY_DARK : 'black'}
                onClick={() => handleStepClick(index)}
                sx={{ cursor: 'pointer' }}
              >
                {step.label}
              </Typography>
            </StepLabel>
            {step.sublabel ? step.sublabel.map((substep, subindex) => (
              <Typography
                fontWeight={currPage === index && currCoveragePage === subindex ? 'bold' : 'normal'}
                color={getEnabled(index, subindex)}
                onClick={() => handleCoverageStepClick(index, subindex)}
                sx={{ cursor: getEnabled(index, subindex) === 'grey' ? 'default' : 'pointer', marginLeft: '32px' }}
              >
                {substep}
              </Typography>
            )) : null}
          </Step>
        ))}
      </Stepper>
      <CensusFileReminderDialog
        content={Constants.CENSUS_FILE_REMINDER_CONTENT.FILE_INVALID}
        open={dialogOpen}
        onClose={handleDialogClose}
      />
      <CancelScenarioDialog
        formState={formStateValue}
        isOpen={cancelDialogOpen}
        onClose={() => cancelScenarioClose(false)}
        handleStepClick={handleStepClick}
        handleCoverageStepClick={handleCoverageStepClick}
        index={dialogIndex}
        subindex={dialogSubindex}
      />
    </Box>
  );
}
