import React from 'react';
import { Typography, IconButton, Grid } from '@mui/material';
import { AddCircleRounded } from '@mui/icons-material';

export default function AddOptionButton({ onClick, isProductIncludedChecked }) {
  return (
    <Grid container alignItems="center" justifyContent="flex-end" sx={{ right: '0' }}>
      <Grid item>
        <IconButton aria-label="add-button" sx={{ float: 'right', marginBottom: '-22px' }} disabled={!isProductIncludedChecked}>
          <AddCircleRounded sx={{ color: isProductIncludedChecked ? '#055f48' : '#d3d3d3' }} onClick={onClick} />
        </IconButton>
      </Grid>
      <Grid item>
        <Typography sx={{ fontSize: 14, marginBottom: '-25px', marginTop: '2px' }}>
          Add Option - up to 5
        </Typography>
      </Grid>
    </Grid>
  );
}
