import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import * as Constants from 'common/Constants';
import { Alert, Snackbar } from '@mui/material';
import { updateSystemErrorMsg } from 'app/state/reducer';
import Spinner from 'common/components/spinner';
import { grey } from '@mui/material/colors';
import ConfirmDialog from 'common/ComfirmationDialog';
import UserAgreement from 'common/userAgreement';
import { refreshToken } from 'features/login/state/service';
import store from 'app/store';
import AppRoute from './app/router/AppRoute';
import './App.css';

const theme = createTheme({
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          paddingTop: '1.5rem',
          paddingBottom: '2rem',
          paddingLeft: '1.5rem',
          paddingRight: '1.5rem',
          margin: 2,
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          backgroundColor: grey[200],
        },
      },
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: 'h4' },
          style: {
            fontSize: '1.5rem',
            fontWeight: 'bold',
            color: 'black',
          },
        },
        {
          props: { variant: 'h5' },
          style: {
            fontSize: '1.25rem',
            fontWeight: 'bold',
            color: Constants.PRIMARY_DARK,
          },
        },
        {
          props: { mystyle: 'paraHeader' },
          style: {
            color: 'black',
            paddingBottom: '6px',
          },
        },
        {
          props: { mystyle: 'pageHeader' },
          style: {
            color: 'black',
            paddingBottom: '40px',
          },
        },
        {
          props: { variant: 'h6' },
          style: {
            fontSize: '1rem',
            fontWeight: 'bold',
            color: Constants.PRIMARY,
          },
        },
        {
          props: { variant: 'body1' },
          style: {
            fontSize: '1rem',
          },
        },
        {
          props: { mystyle: 'download' },
          style: {
            fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif', 'Times'],
          },
        },
        {
          props: { mystyle: 'stepper' },
          style: {
            color: Constants.PRIMARY_DARK,
          },
        },
      ],
    },
    MuiGrid: {
      variants: [
        {
          props: { variant: 'addPaddingBottom' },
          style: {
            paddingBottom: '0.5rem',
          },
        },
      ],
    },
  },
  palette: {
    background: {
      default: '#F0F2F5',
      white: '#ffffff',
    },
    primary: {
      main: Constants.PRIMARY,
      dark: Constants.PRIMARY_DARK,
    },
  },
});

export default function App() {
  const dispatch = useDispatch();
  const jwt = useSelector((state) => state.auth.jwt);
  const jwtExp = useSelector((state) => state.auth.jwtExp);
  const systemMsg = useSelector((state) => state.app.systemMsg);
  const termsAccepted = useSelector((state) => state.auth.termsAccepted);
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const isFetchingQueue = useSelector(
    (state) => state.app.isFetchingQueue,
  );
  const systemMsgSeverity = useSelector(
    (state) => state.app.systemMsgSeverity,
  );

  const onCloseError = () => {
    dispatch(updateSystemErrorMsg(null));
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    if (termsAccepted === false) {
      handleDialogOpen();
    }
    if (termsAccepted === true) {
      handleDialogClose();
    }
  }, [termsAccepted]);

  useEffect(() => {
    const timer = setInterval(
      () => {
        if (jwtExp) {
          const leftTime = (jwtExp * 1000) - Date.now();
          // if left time > 0 then, the token is valid
          // if the token is expired in 5 mins, refresh the token
          if (leftTime > 0 && leftTime < 300000) {
            store.dispatch(refreshToken());
          }
        }
      },
      1000,
    );
    return () => clearInterval(timer);
  });

  return (
    <ThemeProvider theme={theme}>
      {systemMsg && (
        <Snackbar
          open
          onClose={onCloseError}
          severity="error"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          autoHideDuration={15000}
        >
          <Alert severity={systemMsgSeverity} onClose={onCloseError}>
            {systemMsg}
          </Alert>
        </Snackbar>
      )}
      <AppRoute />
      {isFetchingQueue > 0 && (
        <Spinner />
      )}
      <ConfirmDialog />
      {jwt && (
        <UserAgreement
          open={dialogOpen}
          onClose={handleDialogClose}
        />
      )}
    </ThemeProvider>

  );
}

export { theme as uiTheme };
