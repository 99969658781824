import React from 'react';
import {
  Checkbox, AccordionSummary, Grid, Typography, Tooltip,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { formatter, intFormatter, MONEY_SPENT_DEFAULT } from 'common/Constants';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export default function PolicyAccordionSummary(props) {
  const {
    id, title, onChange, setExpanded, moduleCode,
    checked, indeterminate, totalClaims = 0, totalSpent = 0,
    moneySpentLabel, tooltipContent, isInvalid, isDisabled,
  } = props;

  return (
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls={`panel-${id}`}
      id={`panel-${id}-header`}
      onClick={(event) => {
        if (!event.target.id.startsWith(`panel-${id}-header-checkbox`)) {
          setExpanded((prev) => ({ ...prev, [moduleCode]: !prev[moduleCode] }));
        }
      }}
      sx={isInvalid && { backgroundColor: '#fee0e4' }}
    >
      <Grid container>
        <Grid item sx={{ paddingRight: '12px' }}>
          <Checkbox
            id={`panel-${id}-header-checkbox`}
            checked={checked[moduleCode]}
            indeterminate={indeterminate[moduleCode]}
            onClick={() => onChange(moduleCode)}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item>
          <Grid container>
            <Grid container xs={12}>
              <Typography variant="h6" color="black" marginRight={1}>
                {title}
              </Typography>
              <Grid item>
                {tooltipContent !== undefined && tooltipContent
                  ? (
                    <Tooltip
                      title={tooltipContent}
                    >
                      <InfoOutlinedIcon fontSize="small" />
                    </Tooltip>
                  ) : null}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {totalSpent === null
              ? (
                <Typography variant="body1">
                  Number of Claims:
                  {' '}
                  {Number.isNaN(totalClaims) ? '-' : intFormatter.format(totalClaims)}
                </Typography>
              ) : (
                <Typography variant="body1">
                  Number of Claims:
                  {' '}
                  {Number.isNaN(totalClaims) ? '-' : intFormatter.format(totalClaims)}
                  {' '}
                  |
                  {' '}
                  {moneySpentLabel === undefined ? MONEY_SPENT_DEFAULT : moneySpentLabel}
                  :
                  {' '}
                  {formatter.format(totalSpent)}
                </Typography>
              )}
          </Grid>
        </Grid>
      </Grid>
    </AccordionSummary>
  );
}
