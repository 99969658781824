import {
  POLICY_CODE, POLICY_ITEM_TYPE, DEPENDENCY_OBJ_LIST,
} from 'common/Constants';
import { CARRIER_CODE } from 'common/AppConstant';
import * as Constants from 'common/Constants';

export const updateHI = (state, obj, weight, count, payout) => {
  const carrierCode = localStorage.getItem(CARRIER_CODE);
  if (Object.keys(state.claim).length > 0
    && Object.keys(obj).length > 0
    && state.HIPayoutDetail.length > 0) {
    DEPENDENCY_OBJ_LIST.forEach((dependencyObj) => {
      Object.values(state.HIPayoutDetail).forEach((item, index) => {
        if (item.code in state.claim && item.carrierCodes.includes(carrierCode)) {
          const { participationPerc1, participationPerc2 } = state.participationPercentages.HI;
          if (!(count in state.HIPayoutDetail[index])) {
            state.HIPayoutDetail[index][count] = {};
          }
          if (!(payout in state.HIPayoutDetail[index])) {
            state.HIPayoutDetail[index][payout] = {};
          }
          state.HIPayoutDetail[index][count][dependencyObj.code] = Math.round(
            state.claim[item.code][dependencyObj.claimCode] * weight.HI,
          );
          const benefit1 = obj[item.name];
          const benefit2 = obj[`${item.name}2`];
          const noOfclaims = Math.round(state.claim[item.code][dependencyObj.claimCode]
                                * weight.HI);
          const participationRate1 = participationPerc1 / 100;
          const participationRate2 = participationPerc2 / 100;
          const initialPayout = (benefit1 * (participationRate1 * noOfclaims))
                            + (benefit2 * (participationRate2 * noOfclaims));
          if (item.type === POLICY_ITEM_TYPE.PERIOD) {
            state.HIPayoutDetail[index][payout][dependencyObj.code] = Math.round(initialPayout
              * Constants.calculateMaximumDay(
                obj[item.periodName],
                item.periodCode,
              )) || 0;
            state.HIPayoutDetail[index].maximumDay = obj[item.periodName];
          } else {
            state.HIPayoutDetail[index][payout][dependencyObj.code] = initialPayout || 0;
          }
        }
      });

      if (!(count in state.totalCount.HI)) {
        state.totalCount.HI[count] = {};
      }
      if (!(payout in state.totalPayout.HI)) {
        state.totalPayout.HI[payout] = {};
      }

      state.totalPayout.HI[payout][dependencyObj.code] = state.HIPayoutDetail.reduce((
        acc,
        item,
      ) => {
        if (payout in item) {
          return acc + item[payout][dependencyObj.code] * Number(obj[`${item.name}Checked`]);
        }
        return acc;
      }, 0);

      state.totalCount.HI[count][dependencyObj.code] = Object.values(state.HIPayoutDetail).reduce((
        acc,
        item,
      ) => {
        if (count in item) {
          // hard coding here. refactor me.
          if (item.name === 'admission' || item.name === 'nonIcuAdmission' || item.name === 'icuAdmission' || item.name === 'rehabAdmission'
            || item.name === 'outpatientSurgery') {
            return acc;
          } if (item.relatedName !== null) {
            return acc + item[count][dependencyObj.code] * Math.min(Number(obj[`${item.name}Checked`])
              + Number(obj[`${item.relatedName}Checked`]), 1);
          }
          return acc + item[count][dependencyObj.code] * Number(obj[`${item.name}Checked`]);
        }
        return acc;
      }, 0);
    });
  }
};

export const updateCI = (state, obj, weight, count, payout) => {
  const carrierCode = localStorage.getItem(CARRIER_CODE);
  let temp = 0;
  if (Object.keys(state.claim).length > 0
    && Object.keys(obj).length > 0
    && state.CIPayoutDetail.length > 0) {
    Object.values(state.CIPayoutDetail).forEach((item, index) => {
      DEPENDENCY_OBJ_LIST.forEach((dependencyObj, ind) => {
        if (item.code in state.claim && item.carrierCodes.includes(carrierCode)) {
          if (ind === 0) {
            state.CIPayoutDetail[index][count] = { TOTAL: 0 };
            state.CIPayoutDetail[index][payout] = { TOTAL: 0 };
          }
          if (dependencyObj.code !== 'TOTAL') {
            temp = Math.round(
              state.claim[item.code][dependencyObj.claimCode] * weight.CI,
            ) * Number(obj[`${dependencyObj.CICode}Checked`]);
            state.CIPayoutDetail[index][count][dependencyObj.code] = temp;
            state.CIPayoutDetail[index][count].TOTAL += temp;
            temp = 0;
            if (item.type === POLICY_ITEM_TYPE.ORIGINAL) {
              temp = Math.round(
                state.claim[item.code][dependencyObj.claimCode] * weight.CI,
              ) * obj[item.name] * Number(obj[`${dependencyObj.CICode}Checked`]);
            } else if (item.type === POLICY_ITEM_TYPE.PERCENTAGE) {
              const {
                faceValue, faceValueOption2, faceValueOption3, faceValueOption4, faceValueOption5,
                expectedEnrollment, expectedEnrollmentOption2, expectedEnrollmentOption3,
                expectedEnrollmentOption4, expectedEnrollmentOption5,
              } = obj;
              if (dependencyObj.code === 'EMPLOYEE') {
                const { employeeCount } = state.claim[item.code];
                temp = Math.round(((faceValue * obj[item.name]) / 100)
                      * ((employeeCount * weight.CI * expectedEnrollment) / 100))
                      + Math.round(((faceValueOption2 * obj[item.name]) / 100)
                      * ((employeeCount * weight.CI * expectedEnrollmentOption2) / 100))
                      + Math.round(((faceValueOption3 * obj[item.name]) / 100)
                      * ((employeeCount * weight.CI * expectedEnrollmentOption3) / 100))
                      + Math.round(((faceValueOption4 * obj[item.name]) / 100)
                      * ((employeeCount * weight.CI * expectedEnrollmentOption4) / 100))
                      + Math.round(((faceValueOption5 * obj[item.name]) / 100)
                      * ((employeeCount * weight.CI * expectedEnrollmentOption5) / 100));
              } else if (dependencyObj.code === 'SPOUSE' || dependencyObj.code === 'CHILDREN') {
                const claims = state.claim[item.code][dependencyObj.claimCode];
                temp = Math.round((Math.round(((faceValue * obj[item.name]) / 100)
                                * ((claims * weight.CI * expectedEnrollment) / 100))
                                + Math.round(((faceValueOption2 * obj[item.name]) / 100)
                                * ((claims * weight.CI * expectedEnrollmentOption2) / 100))
                                + Math.round(((faceValueOption3 * obj[item.name]) / 100)
                                * ((claims * weight.CI * expectedEnrollmentOption3) / 100))
                                + Math.round(((faceValueOption4 * obj[item.name]) / 100)
                                * ((claims * weight.CI * expectedEnrollmentOption4) / 100))
                                + Math.round(((faceValueOption5 * obj[item.name]) / 100)
                                * ((claims * weight.CI * expectedEnrollmentOption5) / 100)))
                          * ((obj[dependencyObj.CICode] * Number(obj[`${dependencyObj.CICode}Checked`])) / 100));
              }
            }
            state.CIPayoutDetail[index][payout][dependencyObj.code] = temp;
            state.CIPayoutDetail[index][payout].TOTAL += temp;
            temp = 0;
          }
        }
      });
    });

    if (!(count in state.totalCount.CI)) {
      state.totalCount.CI[count] = {};
    }
    if (!(payout in state.totalPayout.CI)) {
      state.totalPayout.CI[payout] = {};
    }

    DEPENDENCY_OBJ_LIST.forEach((dependencyObj) => {
      state.totalPayout.CI[payout][dependencyObj.code] = Object.values(
        state.CIPayoutDetail,
      ).reduce((acc, item) => {
        if (payout in item) {
          return acc + item[payout][dependencyObj.code] * Number(obj[`${item.name}Checked`]);
        }
        return acc;
      }, 0);

      state.totalCount.CI[count][dependencyObj.code] = Object.values(state.CIPayoutDetail).reduce((
        acc,
        item,
      ) => {
        if (count in item) {
          return acc + item[count][dependencyObj.code] * Number(obj[`${item.name}Checked`]);
        }
        return acc;
      }, 0);
    });
  }
};

export const updateAC = (state, obj, weight, count, payout) => {
  const carrierCode = localStorage.getItem(CARRIER_CODE);
  if (Object.keys(state.claim).length > 0
    && Object.keys(obj).length > 0 && state.HIPayoutDetail.length > 0) {
    DEPENDENCY_OBJ_LIST.forEach((dependencyObj) => {
      Object.values(state.ACPayoutDetail).forEach((item, index) => {
        if (item.code in state.claim && item.carrierCodes.includes(carrierCode)) {
          const { participationPerc1, participationPerc2 } = state.participationPercentages.AC;
          if (!(count in state.ACPayoutDetail[index])) {
            state.ACPayoutDetail[index][count] = {};
          }
          if (!(payout in state.ACPayoutDetail[index])) {
            state.ACPayoutDetail[index][payout] = {};
          }

          state.ACPayoutDetail[index][count][dependencyObj.code] = Math.round(
            state.claim[item.code][dependencyObj.claimCode] * weight.AC,
          );
          const benefit1 = obj[item.name];
          const benefit2 = obj[`${item.name}2`];
          const noOfclaims = Math.round(state.claim[item.code][dependencyObj.claimCode]
                                * weight.AC);
          const participationRate1 = participationPerc1 / 100;
          const participationRate2 = participationPerc2 / 100;
          const initialPayout = (benefit1 * (participationRate1 * noOfclaims))
                            + (benefit2 * (participationRate2 * noOfclaims));

          if (item.type === POLICY_ITEM_TYPE.FRACT) {
            const closed1 = obj[`${item.name}Closed`];
            const open1 = obj[`${item.name}Open`];
            const closed2 = obj[`${item.name}Closed2`];
            const open2 = obj[`${item.name}Open2`];
            const { fractureInjuriesWeight } = item;
            const payout1 = Math.round((closed1 * fractureInjuriesWeight + open1
              * (1 - fractureInjuriesWeight)) * (participationRate1 * noOfclaims));
            const payout2 = Math.round((closed2 * fractureInjuriesWeight + open2
                * (1 - fractureInjuriesWeight)) * (participationRate2 * noOfclaims));
            state.ACPayoutDetail[index][payout][dependencyObj.code] = (payout1 + payout2) || 0;
          } else if (item.type === POLICY_ITEM_TYPE.PERIOD) {
            state.ACPayoutDetail[index][payout][dependencyObj.code] = Math.round(initialPayout
              * Constants.calculateMaximumDay(
                obj[item.periodName],
                item.periodCode,
              )) || 0;
            state.ACPayoutDetail[index].maximumDay = obj[item.periodName];
          } else {
            state.ACPayoutDetail[index][payout][dependencyObj.code] = initialPayout || 0;
          }
        }
      });

      if (!(count in state.totalCount.AC)) {
        state.totalCount.AC[count] = {};
      }
      if (!(payout in state.totalPayout.AC)) {
        state.totalPayout.AC[payout] = {};
      }

      state.totalPayout.AC[payout][dependencyObj.code] = Object.values(
        state.ACPayoutDetail,
      ).reduce((acc, item) => {
        if (payout in item) {
          return acc + item[payout][dependencyObj.code] * Number(obj[`${item.name}Checked`]);
        }
        return acc;
      }, 0);

      state.totalCount.AC[count][dependencyObj.code] = Object.values(
        state.ACPayoutDetail,
      ).reduce((acc, item) => {
        if (count in item) {
          // hard coding here. refactor me.
          if (item.name === 'hospitalAdmission' || item.name === 'nonIcuAdmission' || item.name === 'icuAdmission' || item.name === 'rehabAdmission' || item.name === 'surgeryOp') {
            return acc;
          } if (item.relatedName !== null) {
            return acc + item[count][dependencyObj.code] * Math.min(Number(obj[`${item.name}Checked`])
              + Number(obj[`${item.relatedName}Checked`]), 1);
          }
          return acc + item[count][dependencyObj.code] * Number(obj[`${item.name}Checked`]);
        }
        return acc;
      }, 0);
    });
  }
};

export const updateClaimWeightHelper = (state) => {
  if (state.enrollCondConsts.length > 0 && Object.keys(state.policyAdjustments).length > 0) {
    const mapping = [
      { code: POLICY_CODE.HI, cond: 'enrollCondHi', scalar: 'hiScalar' },
      { code: POLICY_CODE.CI, cond: 'enrollCondCi', scalar: 'ciScalar' },
      { code: POLICY_CODE.AC, cond: 'enrollCondAc', scalar: 'acScalar' },
    ];

    mapping.forEach((item) => {
      state.claimWeight[item.code] = (parseFloat(state.policyAdjustments.comLogic)
        * parseFloat(state.enrollCondConsts[Number(state.policyAdjustments.diff)
        * Number(state.policyAdjustments[item.cond])
        + Number(!state.policyAdjustments.diff)
        * Number(state.policyAdjustments.enrollCond)][item.scalar])) / 100;

      state.claimWeightEnroll15[item.code] = (parseFloat(state.policyAdjustments.comLogic)
        * parseFloat(state.enrollCondConsts[Math.min(Number(state.policyAdjustments.diff)
        * Number(state.policyAdjustments[item.cond])
        + Number(!state.policyAdjustments.diff)
        * Number(state.policyAdjustments.enrollCond) + 15, 100)][item.scalar])) / 100;

      state.claimWeightCom15[item.code] = (Math.min(
        parseFloat(state.policyAdjustments.comLogic) + 15,
        100,
      )
        * parseFloat(state.enrollCondConsts[Number(state.policyAdjustments.diff)
        * Number(state.policyAdjustments[item.cond])
        + Number(!state.policyAdjustments.diff)
        * Number(state.policyAdjustments.enrollCond)][item.scalar])) / 100;
    });

    state.hospitalIndemnityPayoutFlag = !state.hospitalIndemnityPayoutFlag;
    state.criticalIllnessPayoutFlag = !state.criticalIllnessPayoutFlag;
    state.accidentPayoutFlag = !state.accidentPayoutFlag;
  }
};

export const updateHospitalIndemnityPayoutHelper = (state) => {
  updateHI(state, state.hospitalIndemnityObject, state.claimWeight, 'count', 'payout');
  updateHI(state, state.hospitalIndemnityObject, state.claimWeightEnroll15, 'countEnroll15', 'payoutEnroll15');
  updateHI(state, state.hospitalIndemnityObject, state.claimWeightCom15, 'countCom15', 'payoutCom15');
  state.comparePlanFlag = !state.comparePlanFlag;
};

export const updateCriticalIllnessPayoutHelper = (state) => {
  updateCI(state, state.criticalIllnessObject, state.claimWeight, 'count', 'payout');
  updateCI(state, state.criticalIllnessObject, state.claimWeightEnroll15, 'countEnroll15', 'payoutEnroll15');
  updateCI(state, state.criticalIllnessObject, state.claimWeightCom15, 'countCom15', 'payoutCom15');
  state.comparePlanFlag = !state.comparePlanFlag;
};

export const updateAccidentPayoutHelper = (state) => {
  updateAC(state, state.accidentObject, state.claimWeight, 'count', 'payout');
  updateAC(state, state.accidentObject, state.claimWeightEnroll15, 'countEnroll15', 'payoutEnroll15');
  updateAC(state, state.accidentObject, state.claimWeightCom15, 'countCom15', 'payoutCom15');
  state.comparePlanFlag = !state.comparePlanFlag;
};
