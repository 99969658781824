import React, {
  useEffect, useState, useRef,
} from 'react';
import {
  Grid, Typography, Card, Divider, Stack, IconButton, Box, TextField, InputAdornment, Tooltip,
} from '@mui/material';
import BottomBar from 'features/navigation/bottomBar';
import KingBedIcon from '@mui/icons-material/KingBed';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import WheelchairPickupIcon from '@mui/icons-material/WheelchairPickup';
import ExportImage from 'common/components/exportImage';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useSelector } from 'react-redux';
import {
  formatter, intFormatter, bestCodes, POLICY_ITEM_TYPE,
  POLICY_CODE, specificBenefitChecked, percFormatterMinZero, percFormatterNoDecimal,
  avgHospitalBenefits, calculateMaximumDay,
} from 'common/Constants';
import * as Constants from 'common/Constants';
import store from 'app/store';
import { CARRIER_CODE, AFLAC_CARRIER_CODE } from 'common/AppConstant';
import {
  Check, Close, Delete, Edit,
} from '@mui/icons-material';
import aflacLogo from 'asset/aflac/aflac-logo.png';
import styled from '@emotion/styled';
import deepCopy from 'common/util/deepCopy';
import HospitalIndemnityIcon from 'asset/icon-hopital-indemnity.svg';
import CriticalIllnessIcon from 'asset/icon-critical-illness.svg';
import AccidentIcon from 'asset/icon-accident.svg';
import PolicyCard from './common/policyCard';
import PolicyCardSupplemental from './common/policyCardSupplemental';
import CarrierDisclaimer from './common/carrierDisclaimer';
import { updateComparePlan } from './state/reducer';
import { deleteCustomTextById, getCustomTextByScenarioId, saveCustomText } from './state/service';
import {
  updateClaimWeightHelper, updateHospitalIndemnityPayoutHelper,
  updateCriticalIllnessPayoutHelper, updateAccidentPayoutHelper,
} from './common/reducerHelperFunctions';

const iconButtonStyle = {
  maxHeight: '25px', maxWidth: '25px',
};

const iconStyle = {
  maxHeight: '20px', maxWidth: '20px',
};

const Label = styled(Typography)({
  fontWeight: 600,
  width: '16.25rem',
});

const LabelContainer = styled(Grid)({
  gap: '1.5rem',
});

export default function Output(props) {
  const { bottomBarFunctions } = props;
  const backgroundRef = useRef();
  const employerInsightsRef = useRef();
  const supplementalInsightsRef = useRef();
  const additionalInsightsRef = useRef();
  const otherObservationRef = useRef();
  const productSummaryRef = useRef();
  const comparePlansRef = useRef();
  const carrierCode = localStorage.getItem(CARRIER_CODE);

  const { handleCancelCreation, goToPreviousPage } = bottomBarFunctions;

  const originalScenario = useSelector(
    (state) => state.scenario,
  );

  const carrierName = useSelector(
    (state) => state.auth.carrierName,
  );

  const totalPayout = useSelector(
    (state) => state.scenario.totalPayout,
  );

  const totalCount = useSelector(
    (state) => state.scenario.totalCount,
  );

  const HIPayoutDetail = useSelector(
    (state) => state.scenario.HIPayoutDetail,
  );
  const CIPayoutDetail = useSelector(
    (state) => state.scenario.CIPayoutDetail,
  );
  const ACPayoutDetail = useSelector(
    (state) => state.scenario.ACPayoutDetail,
  );

  const hospitalIndemnityObject = useSelector(
    (state) => state.scenario.hospitalIndemnityObject,
  );
  const criticalIllnessObject = useSelector(
    (state) => state.scenario.criticalIllnessObject,
  );
  const accidentObject = useSelector(
    (state) => state.scenario.accidentObject,
  );

  const genderDistribution = useSelector(
    (state) => state.scenario.genderDistribution,
  );

  const ageDistribution = useSelector(
    (state) => state.scenario.ageDistribution,
  );

  const currPage = useSelector((state) => state.scenario.scenarioSection);

  const policyAdjustments = useSelector(
    (state) => state.scenario.policyAdjustments,
  );

  const scenarioId = useSelector((state) => state.scenario.scenarioId);

  const customTexts = useSelector((state) => state.scenario.customTexts);
  const customTextRefreshFlag = useSelector((state) => state.scenario.customTextRefreshFlag);

  const thisScenario = useSelector((state) => state.scenario.scenario);
  const relationshipDistribution = useSelector((state) => state.scenario.relationshipDistribution);

  const pPercent = useSelector((state) => state.scenario.participationPercentages);

  const [benefitObject, setBenefitObject] = useState({
    HI: [], HI2: [], CI: [], AC: [], AC2: [],
  });

  const [highLowPayouts, setHighLowPayouts] = useState({
    HI: {}, HI2: {}, AC: {}, AC2: {},
  });

  const [benefitObjectReady, setBenefitObjectReady] = useState(false);

  const [distribution, setDistribution] = useState({
    count: {},
    totalCount: 0,
    malePercent: 0,
    femalePercent: 0,
    otherPercent: 0,
    avgAge: 0,
    avgAgeBench: 0,
  });
  const [customTextFlag, setCustomTextFlag] = useState(false);
  const [customText, setCustomText] = useState('');
  const [HIFlag, setHIFlag] = useState(null);
  const [CIFlag, setCIFlag] = useState(null);
  const [ACFlag, setACFlag] = useState(null);
  const [highestPercentDiffPolicy, setHighestPercentDiffPolicy] = useState({});

  const wellnessHIBenefit = HIPayoutDetail?.find((benefit) => benefit?.code === 'wellness2');
  const wellnessCIBenefit = CIPayoutDetail?.find((benefit) => benefit?.code === 'wellness2');
  const wellnessACBenefit = ACPayoutDetail?.find((benefit) => benefit?.code === 'wellness2');
  const wellnessCheckedForHI = hospitalIndemnityObject[specificBenefitChecked[carrierCode].HI];
  const wellnessCheckedForCI = criticalIllnessObject[specificBenefitChecked[carrierCode].CI];
  const wellnessCheckedForAC = accidentObject[specificBenefitChecked[carrierCode].AC];
  const atLeastOneProductInc = hospitalIndemnityObject?.productIncludedChecked
  || criticalIllnessObject?.productIncludedChecked || accidentObject?.productIncludedChecked;

  const comparePlanObject = [
    {
      code: POLICY_CODE.HI,
      displayName: 'Hospital Indemnity',
    },
    {
      code: POLICY_CODE.CI,
      displayName: 'Critical Illness',
    },
    {
      code: POLICY_CODE.AC,
      displayName: 'Accident',
    },
  ];

  const comparePlanObjectHighLow = [
    {
      code: POLICY_CODE.HI,
      displayName: 'Hospital Indemnity - Benefit 1',
      highLowCode: 'HI',
      isHidden: pPercent.HI.participationPerc1 === 0,
    },
    {
      code: POLICY_CODE.HI,
      displayName: 'Hospital Indemnity - Benefit 2',
      highLowCode: 'HI2',
      isHidden: pPercent.HI.participationPerc2 === 0,
    },
    {
      code: POLICY_CODE.CI,
      displayName: 'Critical Illness',
      highLowCode: 'CI',
    },
    {
      code: POLICY_CODE.AC,
      displayName: 'Accident - Benefit 1',
      highLowCode: 'AC',
      isHidden: pPercent.AC.participationPerc1 === 0,
    },
    {
      code: POLICY_CODE.AC,
      displayName: 'Accident - Benefit 2',
      highLowCode: 'AC2',
      isHidden: pPercent.AC.participationPerc2 === 0,
    },
  ];

  const policyObjects = {
    HI: hospitalIndemnityObject,
    CI: criticalIllnessObject,
    AC: accidentObject,
  };

  const getHighestPercentDiffPolicy = (currentScenario, activeScenario) => {
    const filteredComparePlanObject = comparePlanObject.filter((policy) => {
      if (policy.code === POLICY_CODE.HI && !hospitalIndemnityObject.productIncludedChecked) {
        return false;
      }
      if (policy.code === POLICY_CODE.CI && !criticalIllnessObject.productIncludedChecked) {
        return false;
      }
      if (policy.code === POLICY_CODE.AC && !accidentObject.productIncludedChecked) {
        return false;
      }
      return true;
    });
    const policiesWithDiff = filteredComparePlanObject.map((policy) => {
      const activePayout = activeScenario.totalPayout[policy.code]?.payout?.TOTAL;
      const currentPayout = currentScenario.totalPayout[policy.code]?.payout?.TOTAL;
      const activeCount = activeScenario.totalCount[policy.code]?.count?.TOTAL;
      const currentCount = currentScenario.totalCount[policy.code]?.count?.TOTAL;
      const percCountDiff = (activeCount - currentCount) / currentCount;
      return {
        ...policy,
        percCountDiff: Number.isFinite(percCountDiff) ? percCountDiff : 0,
        payoutDiff: activePayout - currentPayout,
        activePayout,
      };
    });
    const highestPercDiff = policiesWithDiff
      .reduce((a, b) => (a.percCountDiff > b.percCountDiff ? a : b), { percCountDiff: 0 });
    const tiedPolicies = policiesWithDiff
      .filter((policy) => policy.percCountDiff === highestPercDiff.percCountDiff);
    if (tiedPolicies.length === 1) {
      return highestPercDiff;
    }
    return tiedPolicies
      .reduce((a, b) => (a.activePayout > b.activePayout ? a : b), { activePayout: 0 });
  };

  const getClaimCommPercDiff = () => {
    const totalCountHi = hospitalIndemnityObject.productIncludedChecked
      ? totalCount.HI.countCom15.TOTAL - totalCount.HI.count.TOTAL : 0;
    const countCom15 = (totalCount.CI?.countCom15
      ? (totalCount.CI.countCom15.TOTAL - totalCount.CI.count.TOTAL) : 0);
    const totalCountCi = criticalIllnessObject.productIncludedChecked
      ? countCom15 : 0;
    const totalCountAc = accidentObject.productIncludedChecked
      ? (totalCount.AC.countCom15.TOTAL - totalCount.AC.count.TOTAL) : 0;

    const diffSum = totalCountHi + totalCountCi + totalCountAc;
    const hiCount = hospitalIndemnityObject.productIncludedChecked ? totalCount.HI.count.TOTAL : 0;
    const ciCount = criticalIllnessObject.productIncludedChecked ? totalCount.CI.count.TOTAL : 0;
    const acCount = accidentObject.productIncludedChecked ? totalCount.AC.count.TOTAL : 0;
    const countSum = hiCount + ciCount + acCount;
    const percDiff = diffSum / countSum;
    return Number.isFinite(percDiff) ? percDiff : 0;
  };

  const getComparePlanText = (obj, index, isBest = false) => {
    let num = 0;
    if (isBest
      && obj.policyCode in bestCodes[carrierCode]
      && bestCodes[carrierCode][obj.policyCode].includes(obj.name)) {
      num = Constants.getBestBenefitPrice(obj.benefitAmount);
    } else {
      num = obj.benefitAmount;
    }
    if ('type' in obj) {
      if (obj.type === POLICY_ITEM_TYPE.ORIGINAL) {
        return formatter.format(num);
      } if (obj.type === POLICY_ITEM_TYPE.PERIOD) {
        return `${formatter.format(num)} / ${obj.maximumDay} Days`;
      } if (obj.type === POLICY_ITEM_TYPE.PERCENTAGE) {
        return `${num}%`;
      } if (obj.type === POLICY_ITEM_TYPE.FRACT) {
        return `${formatter.format(num)} (open)`;
      }
    }
    return null;
  };

  const isHightlightable = (obj) => obj.policyCode in bestCodes[carrierCode]
    && bestCodes[carrierCode][obj.policyCode].includes(obj.name);

  const getGenderDistributionMessage = () => {
    const male = distribution.malePercent;
    const female = distribution.femalePercent;
    const other = distribution.otherPercent;
    if (male > female && male > other) {
      return `Skews Male at ${male}%`;
    } if (female > male && female > other) {
      return `Skews Female at ${female}%`;
    }
    if (other > female && other > male) {
      return `Skews Other at ${other}%`;
    }
    if (male === female && male === other) {
      return 'Your population is equally distributed';
    }
    if (male === female) {
      return 'Your population is equally distributed between Male and Female';
    }
    if (male === other) {
      return 'Your population is equally distributed between Male and Other';
    }
    return 'Your population is equally distributed between Female and Other';
  };

  const getHighestAgeDistributionData = (data) => {
    const sortedData = [...data];

    function sortArray(a, b) {
      if (a.percentageOfCensus > b.percentageOfCensus) {
        return 1;
      }
      if (a.percentageOfCensus < b.percentageOfCensus) {
        return -1;
      }
      return 0;
    }

    if (sortedData.length > 0) {
      sortedData.sort(sortArray);
    }
    return sortedData[sortedData.length - 1];
  };

  const hasDuplicateHighestAgeDistribution = (highestpercentCensus, array) => {
    const filteredHighs = array.filter((x) => x.percentageOfCensus === highestpercentCensus);
    return filteredHighs.length > 1;
  };

  const recalculateOn20AgeBand = (data) => {
    const dataUpdate = data.map((ageDist) => ({
      ageBand: ageDist.ageBand, percentageOfCensus: ageDist.percentageOfCensus,
    }));

    const combinedAgeDist = [];

    for (let i = 0; i < dataUpdate.length; i += 2) {
      const groupTotal = (i < dataUpdate.length - 1)
        ? dataUpdate[i].percentageOfCensus + dataUpdate[i + 1].percentageOfCensus
        : dataUpdate[i].percentageOfCensus;

      const UpdatedAgeBand = dataUpdate[i].ageBand.split('-').length === 2
        ? `${dataUpdate[i].ageBand.split('-')[0]}-${dataUpdate[i + 1].ageBand.split('-')[1]}`
        : dataUpdate[i].ageBand;
      combinedAgeDist.push({ ageBand: UpdatedAgeBand, percentageOfCensus: groupTotal });
    }
    return combinedAgeDist;
  };

  const getOlderYoungerStatus = (ageBand) => {
    if ((ageBand.includes('+') && parseInt(ageBand[0], 10) === 1) || parseInt(ageBand[0], 10) >= 4) {
      return 'older';
    }
    return 'younger';
  };

  const getHighestAgeDistributionMessage = () => {
    let highestAgeDist = getHighestAgeDistributionData(ageDistribution);
    let { ageBand, percentageOfCensus: percentCensus } = highestAgeDist;
    let oldYoungStatus = getOlderYoungerStatus(ageBand);
    if (hasDuplicateHighestAgeDistribution(percentCensus, ageDistribution)) {
      const combinedAgeDist = recalculateOn20AgeBand(ageDistribution);
      highestAgeDist = getHighestAgeDistributionData(combinedAgeDist);
      percentCensus = highestAgeDist.percentageOfCensus;
      if (hasDuplicateHighestAgeDistribution(percentCensus, combinedAgeDist)) {
        return 'Your population is equally distributed';
      }
      ageBand = highestAgeDist.ageBand;
      oldYoungStatus = getOlderYoungerStatus(ageBand);
      return `Skews ${oldYoungStatus} with ${ageBand} at ${percentCensus}%`;
    }
    return `Skews ${oldYoungStatus} with ${ageBand} at ${percentCensus}%`;
  };

  const enterCustomTextEdit = () => {
    setCustomTextFlag(true);
  };

  const exitCustomTextEdit = () => {
    setCustomTextFlag(false);
  };

  const saveText = () => {
    if (customText !== null && customText.trim() !== '') {
      store.dispatch(saveCustomText({
        scenarioId,
        text: customText,
      }));
    }
    setCustomTextFlag(false);
    setCustomText('');
  };

  const handleCustomTextDelete = (id) => {
    store.dispatch(deleteCustomTextById(id));
  };

  const getOverallPayouts = () => {
    const overallPayouts = {};
    comparePlanObject.forEach(({ code }) => {
      switch (code) {
        case 'HI':
          overallPayouts.HI = wellnessCheckedForHI
            ? totalPayout.HI.payout.TOTAL - wellnessHIBenefit.payout.TOTAL
            : totalPayout.HI.payout.TOTAL;
          break;
        case 'CI':
          overallPayouts.CI = wellnessCheckedForCI
            ? totalPayout.CI.payout.TOTAL - wellnessCIBenefit.payout.TOTAL
            : totalPayout.CI.payout.TOTAL;
          break;
        default:
          overallPayouts.AC = wellnessCheckedForAC
            ? totalPayout.AC.payout.TOTAL - wellnessACBenefit.payout.TOTAL
            : totalPayout.AC.payout.TOTAL;
      }
    });
    return overallPayouts;
  };

  const getSelectedBenefits = () => {
    const HIPayoutDetails = hospitalIndemnityObject.productIncludedChecked ? HIPayoutDetail : [];
    const CIPayoutDetails = criticalIllnessObject.productIncludedChecked ? CIPayoutDetail : [];
    const ACPayoutDetails = accidentObject.productIncludedChecked ? ACPayoutDetail : [];
    return [
      ...HIPayoutDetails,
      ...CIPayoutDetails,
      ...ACPayoutDetails,
    ].filter((benefit) => benefit?.code !== 'wellness2' && policyObjects[benefit?.policyCode][`${benefit?.name}Checked`]);
  };
  const checkIfAllAreZeros = () => {
    const selectedBenefits = getSelectedBenefits();
    return selectedBenefits?.every((benefit) => benefit?.count?.TOTAL === 0);
  };

  const breakSecondTie = (tiedBenefits) => {
    const areSamePolicy = tiedBenefits
      ?.every((benefit) => benefit?.policyCode === tiedBenefits[0]?.policyCode);
    if (areSamePolicy) {
      return tiedBenefits[0]?.displayName;
    }
    const overallPayouts = getOverallPayouts();
    const tiedBenefitsWithOverallPayouts = tiedBenefits
      ?.map((benefit) => ({ ...benefit, overallPayout: overallPayouts[benefit?.policyCode] }));
    const finalBenefit = tiedBenefitsWithOverallPayouts
      ?.reduce((a, b) => (b?.overallPayout > a?.overallPayout ? b : a), { overallPayout: 0 });
    return finalBenefit?.displayName;
  };

  const getMostClaimedBenefit = () => {
    const areAllZeros = checkIfAllAreZeros();
    if (areAllZeros) {
      return 'No benefits to display';
    }
    const selectedBenefits = getSelectedBenefits();
    const highest = selectedBenefits
      ?.reduce((a, b) => (b?.count?.TOTAL > a?.count?.TOTAL ? b : a), { count: { TOTAL: 0 } });
    const tiedBenefits = selectedBenefits?.filter((b) => b?.count?.TOTAL === highest?.count?.TOTAL);
    if (tiedBenefits?.length === 1) {
      return highest?.displayName;
    }
    const highest2 = tiedBenefits
      ?.reduce((a, b) => (b?.payout?.TOTAL > a?.payout?.TOTAL ? b : a), { payout: { TOTAL: 0 } });
    const tiedBenefits2 = tiedBenefits?.filter((b) => b?.payout?.TOTAL === highest2?.payout?.TOTAL);
    if (tiedBenefits2?.length === 1) {
      return highest2?.displayName;
    }
    return breakSecondTie(tiedBenefits2);
  };

  const getHighestAverageClaimedBenefit = () => {
    const areAllZeros = checkIfAllAreZeros();
    if (areAllZeros) {
      return 'No benefits to display';
    }
    const selectedBenefits = getSelectedBenefits();
    const benefitsWithAvg = selectedBenefits?.map((benefit) => {
      const payout = benefit?.payout?.TOTAL || 0;
      const count = benefit?.count?.TOTAL || 0;
      return {
        ...benefit,
        average: (payout / count) || 0,
      };
    });
    const highestAvg = benefitsWithAvg
      ?.reduce((a, b) => (b?.average > a?.average ? b : a), { average: 0 });
    const tiedBenefits = benefitsWithAvg?.filter((b) => b?.average === highestAvg?.average);
    if (tiedBenefits?.length === 1) {
      return highestAvg?.displayName;
    }
    const highest2 = tiedBenefits
      ?.reduce((a, b) => (b?.count?.TOTAL > a?.count?.TOTAL ? b : a), { count: { TOTAL: 0 } });
    const tiedBenefits2 = tiedBenefits?.filter((b) => b?.count?.TOTAL === highest2?.count?.TOTAL);
    if (tiedBenefits2?.length === 1) {
      return highest2?.displayName;
    }
    return breakSecondTie(tiedBenefits2);
  };

  const getAvgHospitalBenefit = () => {
    let payout1 = 0;
    let count1 = 0;
    let payout2 = 0;
    let count2 = 0;
    if (hospitalIndemnityObject[avgHospitalBenefits[carrierCode].benefit1Checked]) {
      const benefit1 = HIPayoutDetail
        .find((benefit) => benefit.name === avgHospitalBenefits[carrierCode]?.benefit1);
      payout1 = benefit1?.payout?.TOTAL;
      count1 = benefit1?.count?.TOTAL;
    }
    if (hospitalIndemnityObject[avgHospitalBenefits[carrierCode].benefit2Checked]) {
      const benefit2 = HIPayoutDetail
        .find((benefit) => benefit.name === avgHospitalBenefits[carrierCode]?.benefit2);
      payout2 = benefit2?.payout?.TOTAL;
      count2 = benefit2?.count?.TOTAL;
    }
    const res = (payout1 + payout2) / (count1 + count2);
    return Number.isFinite(res) ? res : 0;
  };

  const getHighestCIBenefit = () => {
    const benefits = CIPayoutDetail.filter((benefit) => benefit?.module?.code !== 'general' && criticalIllnessObject[`${benefit?.name}Checked`]
    && benefit?.payout?.TOTAL);
    if (benefits?.length === 0) {
      return 'No benefits to display';
    }
    const highestPayout = benefits.reduce((a, b) => (a?.payout?.TOTAL > b?.payout?.TOTAL ? a : b), { payout: { TOTAL: 0 }, displayName: '' })?.payout?.TOTAL;
    const tiedBenefits = benefits?.filter((benefit) => benefit?.payout?.TOTAL === highestPayout);
    return tiedBenefits[0];
  };

  const getTop5AccidentBenefits = () => {
    const benefits = ACPayoutDetail.filter((benefit) => accidentObject[`${benefit?.name}Checked`] && benefit?.count?.TOTAL);
    if (benefits?.length === 0) {
      return 'No benefits to display';
    }
    const sortedBenefits = benefits.sort((a, b) => {
      const secondTotal = b?.count?.TOTAL;
      const firstTotal = a?.count?.TOTAL;
      return secondTotal - firstTotal;
    });
    return sortedBenefits.slice(0, 5).map((b) => b?.displayName);
  };

  const getHighLowPayouts = (item, benefit1, benefit2) => {
    let payout1;
    let payout2;

    const participationRate1 = ((item.policyCode === POLICY_CODE.HI)
      ? pPercent.HI.participationPerc1 : pPercent.AC.participationPerc1) / 100;
    const participationRate2 = ((item.policyCode === POLICY_CODE.HI)
      ? pPercent.HI.participationPerc2 : pPercent.AC.participationPerc2) / 100;
    const noOfClaims = item.count.TOTAL;

    if (item.type === POLICY_ITEM_TYPE.ORIGINAL) {
      payout1 = benefit1 * (participationRate1 * noOfClaims);
      payout2 = benefit2 * (participationRate2 * noOfClaims);
    } else if (item.type === POLICY_ITEM_TYPE.PERIOD) {
      const maxDayCalc = calculateMaximumDay(item.maximumDay, item.periodCode);
      payout1 = (benefit1 * (participationRate1 * noOfClaims)) * maxDayCalc;
      payout2 = (benefit2 * (participationRate2 * noOfClaims)) * maxDayCalc;
    } else {
      const closed1 = accidentObject[`${item.name}Closed`];
      const open1 = accidentObject[`${item.name}Open`];

      const closed2 = accidentObject[`${item.name}Closed2`];
      const open2 = accidentObject[`${item.name}Open2`];

      const fractureWeight = item.fractureInjuriesWeight;
      payout1 = Math.round((closed1 * fractureWeight + open1
        * (1 - fractureWeight)) * (participationRate1 * noOfClaims));
      payout2 = Math.round((closed2 * fractureWeight + open2
       * (1 - fractureWeight)) * (participationRate2 * noOfClaims));
    }
    return {
      HighPayout1: payout1,
      LowPayout2: payout2,
    };
  };

  const getMinimumPlanPayoutHIAC = (policy) => {
    let payoutGood = 0;
    const benefits = benefitObject[policy.highLowCode];
    benefits.forEach((item, index) => {
      if (policy.code === POLICY_CODE.HI && index < 4) {
        payoutGood += item.payout.BENEFIT;
      } else if (policy.code === POLICY_CODE.AC && index < 8) {
        payoutGood += item.payout.BENEFIT;
      }
    });
    return payoutGood;
  };

  const getEnhancedPlanPayoutHIAC = (policy) => {
    let payoutBest = 0;
    const pHighLowCode = policy.highLowCode;
    const benefits = benefitObject[pHighLowCode];
    benefits.forEach((item) => {
      if (item.policyCode in bestCodes[carrierCode]
        && bestCodes[carrierCode][item.policyCode].includes(item.name)) {
        const newPrice = Constants.getBestBenefitPrice(item.benefitAmount);
        let payout = 0;
        if (pHighLowCode.endsWith('2')) {
          payout = getHighLowPayouts(item, 0, newPrice);
          payoutBest += payout.LowPayout2;
        } else {
          payout = getHighLowPayouts(item, newPrice, 0);
          payoutBest += payout.HighPayout1;
        }
      } else {
        payoutBest += item.payout.BENEFIT;
      }
    });
    return payoutBest;
  };

  const renderOutputContent = () => (
    <Grid container spacing={2} padding={3}>
      {carrierCode === AFLAC_CARRIER_CODE
        && (
          <Grid
            item
            xs={12}
            style={{ height: 850 }}
            className="printOnly"
          >
            <Grid
              container
              spacing={2}
              padding={3}
              direction="column"
              justifyContent="center"
              alignItems="center"
              style={{ height: '100%' }}
            >
              <img src={aflacLogo} alt="aflacLogo" />
            </Grid>
          </Grid>
        )}
      <Grid item xs={12}>
        <Typography variant="h4">
          Output
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          Using our machine learning matched cohort engine, we&apos;ve put together the
          best estimate of claims for your population. The following insights and plan
          recommendations are based upon your expected financial and utilization results
          for the next plan year.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Card ref={backgroundRef}>
          <Grid container spacing={2}>
            <Grid item xs={12} container variant="addPaddingBottom" justifyContent="space-between">
              <Typography variant="h5">
                Background
              </Typography>
              <ExportImage sectionRef={backgroundRef} sectionName="background" />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <LabelContainer container marginBottom="1rem">
                <Grid item>
                  <Label variant="body1">
                    {thisScenario?.employerName}
                    {' '}
                    size
                  </Label>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    {intFormatter.format(distribution.count.EMPLOYEE)}
                    {' '}
                    employees
                    {' '}
                  </Typography>
                </Grid>
              </LabelContainer>
              <LabelContainer container marginBottom="1rem">
                <Grid item>
                  <Label variant="body1"> Total covered population </Label>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    {intFormatter.format(distribution.totalCount)}
                  </Typography>
                </Grid>
              </LabelContainer>
              <LabelContainer container marginBottom="1rem">
                <Grid item>
                  <Label variant="body1"> Total number of claims </Label>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    {intFormatter.format(totalCount.HI.count.TOTAL
                      + (totalCount.CI?.count?.TOTAL ? totalCount.CI.count.TOTAL : 0)
                      + totalCount.AC.count.TOTAL)}
                  </Typography>
                </Grid>
              </LabelContainer>
              <LabelContainer container marginBottom="1rem">
                <Grid item>
                  <Label variant="body1"> Average projected claim by person </Label>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    {distribution.totalCount > 0
                      ? formatter.format((totalPayout.HI.payout.TOTAL
                        + (totalPayout.CI?.payout?.TOTAL ? totalPayout.CI.payout.TOTAL : 0)
                        + totalPayout.AC.payout.TOTAL)
                        / distribution.totalCount)
                      : 0}
                  </Typography>
                </Grid>
              </LabelContainer>
              <LabelContainer container marginBottom="1rem">
                <Grid item>
                  <Label variant="body1"> Age Distribution </Label>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    {getHighestAgeDistributionMessage()}
                  </Typography>
                </Grid>
              </LabelContainer>
              <LabelContainer container marginBottom="1rem">
                <Grid item>
                  <Label variant="body1"> Gender Distribution </Label>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    {getGenderDistributionMessage()}
                  </Typography>
                </Grid>
              </LabelContainer>
              <LabelContainer container>
                <Grid item>
                  <Label variant="body1"> Relationship </Label>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    {distribution.totalCount > 0
                      ? percFormatterMinZero.format((distribution.count.EMPLOYEE)
                        / distribution.totalCount)
                      : 0}
                    {' '}
                    Employees
                  </Typography>
                </Grid>
              </LabelContainer>
            </Grid>
          </Grid>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card ref={employerInsightsRef}>
          <Grid container spacing={2}>
            <Grid item xs={12} container variant="addPaddingBottom" justifyContent="space-between">
              <Typography variant="h5">
                Employer Insights
              </Typography>
              <ExportImage sectionRef={employerInsightsRef} sectionName="employer_insights" />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid container justifyContent="center" sx={{ marginTop: '2rem' }}>
              <Grid>
                <Card
                  variant="outlined"
                  sx={{
                    width: '17rem',
                    paddingBottom: '1.5rem',
                    marginRight: '4rem',
                    borderRadius: '10px',
                    border: '1px solid rgba(0, 0, 0, 0.32)',
                  }}
                >
                  <Typography sx={{
                    fontSize: '1.5rem',
                    fontWeight: '600',
                    color: '#002E74',
                    textAlign: 'center',
                  }}
                  >
                    {getMostClaimedBenefit()}
                  </Typography>
                  <Typography sx={{
                    fontSize: '0.875rem',
                    fontWeight: '400',
                    color: '#353B43',
                    textAlign: 'center',
                    marginTop: '0.5rem',
                  }}
                  >
                    Most Claimed Event
                  </Typography>
                </Card>
              </Grid>
              <Grid>
                <Card
                  variant="outlined"
                  sx={{
                    width: '17rem',
                    paddingBottom: '1.5rem',
                    borderRadius: '10px',
                    border: '1px solid rgba(0, 0, 0, 0.32)',
                  }}
                >
                  <Typography sx={{
                    fontSize: '1.5rem',
                    fontWeight: '600',
                    color: '#002E74',
                    textAlign: 'center',
                  }}
                  >
                    {getHighestAverageClaimedBenefit()}
                  </Typography>
                  <Typography sx={{
                    fontSize: '0.875rem',
                    fontWeight: '400',
                    color: '#353B43',
                    textAlign: 'center',
                    marginTop: '0.5rem',
                  }}
                  >
                    Highest Average Claim
                  </Typography>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card ref={supplementalInsightsRef}>
          <Grid container spacing={2}>
            <Grid item xs={12} container variant="addPaddingBottom" justifyContent="space-between">
              <Typography variant="h5">
                Supplemental Insights
              </Typography>
              <ExportImage sectionRef={supplementalInsightsRef} sectionName="supplemental_insights" />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Typography sx={{ padding: '16px 0 0 16px' }}>
              Cutting-edge insights on the national working population compared to your employees.
            </Typography>
            <Grid container spacing={2} item xs={12} justifyContent="center">
              {atLeastOneProductInc ? (
                <>
                  {hospitalIndemnityObject?.productIncludedChecked && (
                    <Grid item xs={4}>
                      <PolicyCardSupplemental
                        policyCode="HI"
                        backgroundIcon={HospitalIndemnityIcon}
                        avgHospitalBenefit={getAvgHospitalBenefit()}
                      />
                    </Grid>
                  )}
                  {criticalIllnessObject?.productIncludedChecked && (
                    <Grid item xs={4}>
                      <PolicyCardSupplemental
                        policyCode="CI"
                        backgroundIcon={CriticalIllnessIcon}
                        highestCIBenefit={getHighestCIBenefit()}
                      />
                    </Grid>
                  )}
                  {accidentObject?.productIncludedChecked && (
                    <Grid item xs={4}>
                      <PolicyCardSupplemental
                        policyCode="AC"
                        backgroundIcon={AccidentIcon}
                        top5AccidentBenefits={getTop5AccidentBenefits()}
                      />
                    </Grid>
                  )}
                </>
              ) : (
                <Grid item xs={12} container justifyContent="center" alignItems="center">
                  <Typography variant="body1">No product(s) to display</Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card ref={additionalInsightsRef}>
          <Grid container spacing={2}>
            <Grid item xs={12} container variant="addPaddingBottom" justifyContent="space-between">
              <Typography variant="h5">
                Additional Insights
              </Typography>
              <ExportImage sectionRef={additionalInsightsRef} sectionName="additional_insights" />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {atLeastOneProductInc ? (
              <Grid item xs={6} sx={{ margin: '0 auto' }}>
                <Typography sx={{
                  fontSize: '1.5rem',
                  fontWeight: '600',
                  color: '#002E74',
                  marginBottom: '0.25rem',
                  textAlign: 'center',
                }}
                >
                  Active Enrollment
                </Typography>
                <Typography sx={{ fontSize: '1rem', marginBottom: '0.5rem', textAlign: 'center' }}>
                  We predict that an active enrollment would increase your participation in&nbsp;
                  <strong>
                    {highestPercentDiffPolicy?.displayName}
                  </strong>
                  &nbsp;by&nbsp;
                  <strong>
                    {percFormatterNoDecimal.format(highestPercentDiffPolicy?.percCountDiff)}
                  </strong>
                  , also resulting in the following:
                </Typography>
                <Box display="flex" alignContent="center" flexWrap="wrap">
                  <CheckCircleIcon sx={{ color: '#005c2c' }} fontSize="small" />
                  <Typography sx={{ marginLeft: '0.25rem', fontSize: '1rem' }}>
                    Increase claims by&nbsp;
                    <strong>{formatter.format(highestPercentDiffPolicy?.payoutDiff)}</strong>
                  </Typography>
                </Box>
                <Box display="flex" alignContent="center" flexWrap="wrap" sx={{ marginTop: '0.5rem' }}>
                  <CheckCircleIcon sx={{ color: '#005c2c' }} fontSize="small" />
                  <Typography sx={{ marginLeft: '0.25rem', fontSize: '1rem' }}>
                    Increased communication would increase claims filed by&nbsp;
                    <strong>
                      {percFormatterNoDecimal.format(getClaimCommPercDiff())}
                    </strong>
                  </Typography>
                </Box>
              </Grid>
            ) : (
              <Grid item xs={12} container justifyContent="center" alignItems="center">
                <Typography variant="body1">No product(s) to display</Typography>
              </Grid>
            )}
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card ref={otherObservationRef}>
          <Grid container spacing={2}>
            <Grid item xs={12} variant="addPaddingBottom">
              <Box sx={{ display: 'flex' }}>
                <Typography variant="h5">
                  Other Observations
                </Typography>
                {customTextFlag ? (
                  <Box sx={{ marginLeft: 'auto' }}>
                    <IconButton
                      sx={iconButtonStyle}
                      onClick={() => exitCustomTextEdit()}
                    >
                      <Close sx={{ ...iconStyle, color: '#002E74' }} />
                    </IconButton>
                  </Box>
                )
                  : (
                    <Box sx={{ marginLeft: 'auto' }}>
                      <Tooltip title="Edit custom texts">
                        <IconButton
                          sx={iconButtonStyle}
                          onClick={() => enterCustomTextEdit()}
                        >
                          <Edit sx={{ ...iconStyle, color: '#002E74' }} />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                <ExportImage sectionRef={otherObservationRef} sectionName="other_observations" isOtherObservation />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              {customTexts.map((item) => (
                <Typography variant="body1">
                  {customTextFlag && (
                    <Tooltip title="Delete" placement="left">
                      <IconButton
                        sx={iconButtonStyle}
                        onClick={() => handleCustomTextDelete(item.id)}
                      >
                        <Delete sx={iconStyle} />
                      </IconButton>
                    </Tooltip>
                  )}
                  {'\u2022 '}
                  {item.text}
                </Typography>
              ))}
              {customTextFlag && (
                <TextField
                  sx={{ mt: 2 }}
                  variant="standard"
                  placeholder="Edit your text here..."
                  multiline
                  fullWidth
                  autoFocus
                  value={customText}
                  onChange={(e) => setCustomText(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => saveText()}
                        >
                          <Check />
                        </IconButton>
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => exitCustomTextEdit()}
                        >
                          <Close />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card ref={productSummaryRef}>
          <Grid container spacing={2}>
            <Grid item xs={12} container variant="addPaddingBottom" justifyContent="space-between">
              <Typography variant="h5">
                Product Summary
              </Typography>
              <ExportImage sectionRef={productSummaryRef} sectionName="product_summary" />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid container spacing={2} item xs={12} justifyContent="center">
              {HIFlag && hospitalIndemnityObject?.productIncludedChecked ? (
                <Grid item xs={4}>
                  <PolicyCard
                    hasCheck={false}
                    policies={1}
                    benefits={7}
                    payout={totalPayout.HI.payout.TOTAL}
                    people={totalCount.HI.count.TOTAL}
                    displayName="Hospital Indemnity"
                    backgroundIcon={(
                      <KingBedIcon
                        sx={{ fontSize: '100px' }}
                      />
                  )}
                    specificBenefit={wellnessHIBenefit}
                    specificBenefitChecked={wellnessCheckedForHI}
                  />
                </Grid>
              ) : null}
              {CIFlag && criticalIllnessObject?.productIncludedChecked ? (
                <Grid item xs={4}>
                  <PolicyCard
                    hasCheck={false}
                    policies={5}
                    benefits={31}
                    payout={totalPayout.CI.payout.TOTAL}
                    people={totalCount.CI.count.TOTAL}
                    displayName="Critical Illness"
                    backgroundIcon={<LocalHospitalIcon sx={{ fontSize: '100px' }} />}
                    specificBenefit={wellnessCIBenefit}
                    specificBenefitChecked={wellnessCheckedForCI}
                  />
                </Grid>
              ) : null}
              {ACFlag && accidentObject?.productIncludedChecked ? (
                <Grid item xs={4}>
                  <PolicyCard
                    hasCheck={false}
                    policies={4}
                    benefits={40}
                    payout={totalPayout.AC.payout.TOTAL}
                    people={totalCount.AC.count.TOTAL}
                    displayName="Accident"
                    backgroundIcon={<WheelchairPickupIcon sx={{ fontSize: '100px' }} />}
                    specificBenefit={wellnessACBenefit}
                    specificBenefitChecked={wellnessCheckedForAC}
                  />
                </Grid>
              ) : null }
              {(!HIFlag && !CIFlag && !ACFlag) || (!accidentObject.productIncludedChecked
              && !criticalIllnessObject.productIncludedChecked
              && !hospitalIndemnityObject.productIncludedChecked) ? (
                <Grid item xs={12} container justifyContent="center" alignItems="center">
                  <Typography variant="body1">No product(s) to display</Typography>
                </Grid>
                ) : null}
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card ref={comparePlansRef}>
          <Grid
            container
            spacing={2}
            sx={{
              position: 'relative',
              height: '100%',
            }}
          >
            <Grid item xs={12} container variant="addPaddingBottom" justifyContent="space-between">
              <Typography variant="h5">
                Compare Plans
              </Typography>
              <ExportImage sectionRef={comparePlansRef} sectionName="compare_plans" />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid container spacing={2} margin={0} marginTop={3}>
              {atLeastOneProductInc ? (
                <>
                  <Grid item container xs={12}>
                    <Grid item xs={5.5}>
                      {null}
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="h5" align="center">
                        Minimum Plan Design
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="h5" align="center">
                        Modeled Plan Design
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="h5" align="center">
                        Enhanced Plan Design
                      </Typography>
                    </Grid>
                  </Grid>
                  {comparePlanObjectHighLow.map((policy) => (
                    benefitObject[policy.highLowCode]?.length > 0 && !policy.isHidden
                      ? (
                        <>
                          <Grid item xs={12}>
                            <Typography variant="h6">
                              {policy.displayName}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} marginTop={-1.5}>
                            <Divider color={Constants.PRIMARY} />
                          </Grid>
                          {benefitObject[policy.highLowCode]?.map((item, index) => (
                            <Grid item container xs={12}>
                              <Grid item xs={5.5}>
                                {item.displayName}
                              </Grid>
                              <Grid item xs={2} align="center">
                                {(policy.code === POLICY_CODE.HI && index < 4)
                              || (policy.code === POLICY_CODE.CI && index < 8)
                              || (policy.code === POLICY_CODE.AC && index < 8)
                                  ? getComparePlanText(item, index) : null}
                              </Grid>
                              <Grid item xs={2} align="center">
                                {getComparePlanText(item, index) ?? 0}
                              </Grid>
                              <Grid item xs={2} align="center">
                                <Typography fontWeight={isHightlightable(item) ? 'bold' : 'regular'}>
                                  {getComparePlanText(item, index, true)}
                                </Typography>
                              </Grid>
                            </Grid>
                          ))}
                          <Grid item container xs={12}>
                            <Grid item xs={5.5}>
                              <Typography variant="h6" color="black">
                                Sub Total
                              </Typography>
                            </Grid>
                            <Grid item xs={2} align="center">
                              <Typography fontWeight="bold">
                                {'payoutGood' in totalPayout[policy.code] && policy.code === POLICY_CODE.CI ? formatter.format(totalPayout[policy.code].payoutGood.TOTAL) : null}
                                {policy.code === POLICY_CODE.HI || policy.code === POLICY_CODE.AC
                                  ? formatter.format(highLowPayouts[policy.highLowCode].payoutGood)
                                  : null}
                              </Typography>
                            </Grid>
                            <Grid item xs={2} align="center">
                              <Typography fontWeight="bold">
                                {'payout' in totalPayout[policy.code] && policy.code === POLICY_CODE.CI ? formatter.format(totalPayout[policy.code].payout.TOTAL) : null}
                                {policy.code === POLICY_CODE.HI || policy.code === POLICY_CODE.AC
                                  ? formatter.format(highLowPayouts[policy.highLowCode].payout)
                                  : null }
                              </Typography>
                            </Grid>
                            <Grid item xs={2} align="center">
                              <Typography fontWeight="bold">
                                {'payoutBest' in totalPayout[policy.code] && policy.code === POLICY_CODE.CI ? formatter.format(totalPayout[policy.code].payoutBest.TOTAL) : null}
                                {policy.code === POLICY_CODE.HI || policy.code === POLICY_CODE.AC
                                  ? formatter.format(highLowPayouts[policy.highLowCode].payoutBest)
                                  : null}
                              </Typography>
                            </Grid>
                          </Grid>
                        </>
                      ) : null
                  ))}

                  <Grid item xs={12} marginTop={-1.5}>
                    <Divider color={Constants.PRIMARY} />
                  </Grid>
                  <Grid item container xs={12}>
                    <Grid item xs={5.5}>
                      <Typography variant="h5">
                        Expected Total Projected Claims
                      </Typography>
                    </Grid>
                    <Grid item xs={2} align="center">
                      <Typography variant="h5">
                        {'payoutGood' in totalPayout.HI
                          ? Constants.formatter.format(
                            highLowPayouts.HI.payoutGood
                        + highLowPayouts.HI2.payoutGood
                        + (totalPayout.CI?.payoutGood?.TOTAL ? totalPayout.CI.payoutGood.TOTAL : 0)
                        + highLowPayouts.AC.payoutGood
                        + highLowPayouts.AC2.payoutGood,
                          ) : null}
                      </Typography>
                    </Grid>
                    <Grid item xs={2} align="center">
                      <Typography variant="h5">
                        {Constants.formatter.format(
                          totalPayout.HI.payout.TOTAL
                      + (totalPayout.CI?.payout?.TOTAL ? totalPayout.CI.payout.TOTAL : 0)
                      + totalPayout.AC.payout.TOTAL,
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={2} align="center">
                      <Typography variant="h5">
                        {'payoutBest' in totalPayout.HI
                          ? Constants.formatter.format(
                            highLowPayouts.HI.payoutBest
                        + highLowPayouts.HI2.payoutBest
                        + (totalPayout.CI?.payoutBest?.TOTAL ? totalPayout.CI.payoutBest.TOTAL : 0)
                        + highLowPayouts.AC.payoutBest
                        + highLowPayouts.AC2.payoutBest,
                          ) : null}
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <Grid item xs={12} container justifyContent="center" alignItems="center">
                  <Typography variant="body1">No product(s) to display</Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item sx={12} className="printOnly">
        <CarrierDisclaimer />
      </Grid>
      <Grid item xs={12} className="printOnly">
        {Constants.PDF_MESSAGE.replace(Constants.CARRIER_TOKEN, carrierName)}
      </Grid>
    </Grid>
  );

  useEffect(() => {
    const activeScenario = deepCopy(originalScenario);
    activeScenario.policyAdjustments = {
      ...activeScenario.policyAdjustments,
      diff: false,
      enrollCond: 100,
    };
    updateClaimWeightHelper(activeScenario);
    updateHospitalIndemnityPayoutHelper(activeScenario);
    updateCriticalIllnessPayoutHelper(activeScenario);
    updateAccidentPayoutHelper(activeScenario);
    setHighestPercentDiffPolicy(getHighestPercentDiffPolicy(originalScenario, activeScenario));
  }, []);

  useEffect(() => {
    const temp = {
      count: {},
      totalCount: 0,
      malePercent: 0,
      femalePercent: 0,
      otherPercent: 0,
      avgAge: 0,
      avgAgeBench: 0,
    };
    relationshipDistribution.forEach((obj) => {
      temp.count[obj.name] = obj.countOfCensus;
      temp.totalCount += obj.countOfCensus;
    });
    genderDistribution.forEach((obj) => {
      if ('name' in obj && obj.name === 'MALE') {
        temp.malePercent += obj.percentageOfCensus;
      }
      if ('name' in obj && obj.name === 'FEMALE') {
        temp.femalePercent += obj.percentageOfCensus;
      }
      if ('name' in obj && obj.name === 'OTHERS') {
        temp.otherPercent += obj.percentageOfCensus;
      }
    });
    ageDistribution.forEach((obj) => {
      const tempArray = obj.ageBand.split(/\+|-/);
      if (tempArray.length === 2) {
        if (Number.isInteger(Number(tempArray[0]))) {
          if (tempArray[1] === '') {
            temp.avgAge += (Number(tempArray[0]) * obj.percentageOfCensus) / 100;
            temp.avgAgeBench += (Number(tempArray[0]) * obj.percentageOfBenchmark) / 100;
          } else if (Number.isInteger(Number(tempArray[1]))) {
            temp.avgAge += ((Number(tempArray[0]) + Number(tempArray[1]))
              * obj.percentageOfCensus) / 200;
            temp.avgAgeBench += ((Number(tempArray[0]) + Number(tempArray[1]))
              * obj.percentageOfBenchmark) / 200;
          }
        }
      }
    });
    setDistribution(temp);
  }, [ageDistribution, genderDistribution, relationshipDistribution]);

  useEffect(() => {
    const tempList1 = [];
    const tempList1Low = [];
    const tempList2 = [];
    const tempList3 = [];
    let payout1TotalHI = 0;
    let payout2TotalHI = 0;
    if (hospitalIndemnityObject.productIncludedChecked) {
      HIPayoutDetail.forEach((item) => {
        if (item.code !== '' && hospitalIndemnityObject[`${item.name}Checked`] && 'payout' in item) {
          const benefit1 = hospitalIndemnityObject[item.name];
          const benefit2 = hospitalIndemnityObject[`${item.name}2`];
          const { HighPayout1, LowPayout2 } = getHighLowPayouts(item, benefit1, benefit2);
          payout1TotalHI += HighPayout1;
          payout2TotalHI += LowPayout2;
          tempList1.push({
            ...item,
            payout: { ...item.payout, BENEFIT: HighPayout1 },
            benefitAmount: benefit1,
          });
          tempList1Low.push({
            ...item,
            payout: { ...item.payout, BENEFIT: LowPayout2 },
            benefitAmount: benefit2,
          });
        }
      });
      tempList1.sort((a, b) => ((a.payout.BENEFIT < b.payout.BENEFIT) ? 1 : -1));
      tempList1Low.sort((a, b) => ((a.payout.BENEFIT < b.payout.BENEFIT) ? 1 : -1));
      setBenefitObject((prev) => ({
        ...prev,
        HI: tempList1,
        HI2: tempList1Low,
      }));

      setHighLowPayouts((prev) => ({
        ...prev,
        HI: { payout: payout1TotalHI },
        HI2: { payout: payout2TotalHI },
      }));
      const filteredTempList1 = tempList1.filter((item) => item.benefitAmount > 0);
      setHIFlag(filteredTempList1.length > 0);
    }
    if (criticalIllnessObject.productIncludedChecked) {
      const generalCIList = [];
      CIPayoutDetail.forEach((item) => {
        if (item.code !== '' && criticalIllnessObject[`${item.name}Checked`] && 'payout' in item) {
          tempList2.push({ ...item, benefitAmount: criticalIllnessObject[item.name] });
        } else if (item.code !== '' && criticalIllnessObject[`${item.name}Checked`] && criticalIllnessObject[`${item.name}`]) {
          generalCIList.push({ ...item, benefitAmount: criticalIllnessObject[item.name] });
        }
      });
      tempList2.sort((a, b) => ((a.payout.TOTAL < b.payout.TOTAL) ? 1 : -1));
      setBenefitObject((prev) => ({
        ...prev,
        CI: tempList2,
      }));
      const filteredTempList2 = (tempList2.filter((item) => item.benefitAmount > 0));
      setCIFlag(filteredTempList2.length > 0 || generalCIList.length);
    }
    if (accidentObject.productIncludedChecked) {
      const tempList3Low = [];
      let payout1TotalAC = 0;
      let payout2TotalAC = 0;
      ACPayoutDetail.forEach((item) => {
        if (item.code !== '' && accidentObject[`${item.name}Checked`] && 'payout' in item) {
          if (item.type === POLICY_ITEM_TYPE.FRACT) {
            const { HighPayout1, LowPayout2 } = getHighLowPayouts(item, 0, 0);
            payout1TotalAC += HighPayout1;
            payout2TotalAC += LowPayout2;
            tempList3.push({
              ...item,
              payout: { ...item.payout, BENEFIT: HighPayout1 },
              benefitAmount: accidentObject[`${item.name}Open`],
            });
            tempList3Low.push({
              ...item,
              payout: { ...item.payout, BENEFIT: LowPayout2 },
              benefitAmount: accidentObject[`${item.name}Open2`],
            });
          } else {
            const benefit1 = accidentObject[item.name];
            const benefit2 = accidentObject[`${item.name}2`];
            const { HighPayout1, LowPayout2 } = getHighLowPayouts(item, benefit1, benefit2);
            payout1TotalAC += HighPayout1;
            payout2TotalAC += LowPayout2;
            tempList3.push({
              ...item,
              payout: { ...item.payout, BENEFIT: HighPayout1 },
              benefitAmount: benefit1,
            });
            tempList3Low.push({
              ...item,
              payout: { ...item.payout, BENEFIT: LowPayout2 },
              benefitAmount: benefit2,
            });
          }
        }
      });
      tempList3.sort((a, b) => ((a.payout.BENEFIT < b.payout.BENEFIT) ? 1 : -1));
      tempList3Low.sort((a, b) => ((a.payout.BENEFIT < b.payout.BENEFIT) ? 1 : -1));
      setBenefitObject((prev) => ({
        ...prev,
        AC: tempList3,
        AC2: tempList3Low,
      }));
      setHighLowPayouts((prev) => ({
        ...prev,
        AC: { payout: payout1TotalAC },
        AC2: { payout: payout2TotalAC },
      }));
      const filteredTempList3 = (tempList3.filter((item) => item.benefitAmount > 0));
      setACFlag(filteredTempList3.length > 0);
    }
    setBenefitObjectReady(true);

    store.dispatch(updateComparePlan({
      HI: tempList1.map((x) => x.name),
      CI: tempList2.map((x) => x.name),
      AC: tempList3.map((x) => x.name),
    }));
  }, [currPage]);

  useEffect(() => {
    if (benefitObjectReady) {
      const payoutsHLNew = { ...highLowPayouts };
      comparePlanObjectHighLow.forEach((policy) => {
        if (policy.code === POLICY_CODE.HI || policy.code === POLICY_CODE.AC) {
          payoutsHLNew[policy.highLowCode] = {
            ...payoutsHLNew[policy.highLowCode],
            payoutGood: Math.round(getMinimumPlanPayoutHIAC(policy)),
            payoutBest: Math.round(getEnhancedPlanPayoutHIAC(policy)),
          };
        }
      });
      setHighLowPayouts(payoutsHLNew);
      setBenefitObjectReady(false);
    }
  }, [benefitObjectReady]);

  useEffect(() => {
    const temp = { HI: 0, CI: 0, AC: 0 };
    if (policyAdjustments.diff) {
      if (Number(policyAdjustments.enrollCondHi) <= 50) {
        temp.HI = 1;
      }
      if (Number(policyAdjustments.enrollCondCi) <= 50) {
        temp.CI = 1;
      }
      if (Number(policyAdjustments.enrollCondAc) <= 50) {
        temp.AC = 1;
      }
    } else if (Number(policyAdjustments.enrollCond) <= 50) {
      temp.HI = 1;
      temp.CI = 1;
      temp.AC = 1;
    }
  }, [currPage, policyAdjustments]);

  useEffect(() => {
    store.dispatch(getCustomTextByScenarioId(scenarioId));
  }, [customTextRefreshFlag]);

  return (
    <Stack>
      <Box>
        {renderOutputContent(false)}
      </Box>
      <Grid item xs={12}>
        <BottomBar
          firstButtonDisplayName="EXIT"
          secondButtonDisplayName="SAVE AND CLOSE"
          onClickFirstButton={handleCancelCreation}
          onClickSecondButton={handleCancelCreation}
          isBackButton
          onClickBackButton={goToPreviousPage}
        />
      </Grid>
    </Stack>
  );
}
